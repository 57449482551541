<template>
    <div class="main">
        <div class="body-content">
            <h1>Ova Report</h1>
            <div class="form-group">
                <div class="form-item">
                    <label for="partyname">Party *</label>
                    <v-select :clearable="false" tabindex="0" placeholder="Select Party Name" id="partyname"
                        v-model="newContainer.partyName" :options="party" label="short_name"
                        :reduce="party => party.autoid" selectOnTab="True" />
                </div>

                <div class="form-item">
                    <label for="vesselName">Vessel *</label>
                    <v-select :clearable="false" id="vesselName" placeholder="Select Vessel"
                        v-model="newContainer.vesselID" ref="vesselName" :options="vessel" label="Name"
                        :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
                </div>

                <div class="form-item">
                    <label for="voyageName">Voyage *</label>
                    <v-select :clearable="false" id="voyageName" v-model="newContainer.VoyId" :options="voyage"
                        label="Name" :reduce="voyage => voyage.Voyageid" selectOnTab="True" />
                </div>

                <div class="form-item">
                    <label for="movementname">Movement Type *</label>
                    <v-select :clearable="false" id="movementname" v-model="newContainer.movementID"
                        :options="movementOptions" label="Name" :reduce="movement => movement.Movid"
                        selectOnTab="True" />
                </div>

                <div class="form-item">
                    <label for="location">Location:</label>
                    <v-select :clearable="false" id="location" v-model="newContainer.locID" :options="location"
                        label="Name" :reduce="location => location.locationid" selectOnTab="True" />
                </div>
            </div>
            <div class="form-item">
                <button type="button" class="excel-button" @click="ova_report">Generate Report</button>
            </div>
            <div class="form-item">
                <div v-html="htmlContent"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import { part } from 'core-js/core/function';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'

export default {
    components: {
        VSelect,
    },
    data() {
        return {
            transactions: [],
            party: JSON.parse(localStorage.getItem('party')),
            accounts: JSON.parse(localStorage.getItem('accounts')) || [],
            applicants: JSON.parse(localStorage.getItem('applicants')) || [],
            vessel: JSON.parse(localStorage.getItem('vessel')) || [],
            voyage: JSON.parse(localStorage.getItem('voyage')) || [],
            movementOptions: JSON.parse(localStorage.getItem('movements')) || [],
            ports: JSON.parse(localStorage.getItem('ports')) || [],
            location: JSON.parse(localStorage.getItem('locations')) || [],
            cfs_location: JSON.parse(localStorage.getItem('cfs_location')) || [],
            newContainer: {
                partyName: 0,
                applicantID: 0,
                accountID: 0,
                vesselID: 0,
                VoyId: 0,
                movementID: 0,
                locID: 0,
                file: null,
            },
            htmlContent: null,
        }
    },
    methods: {
        updateApplicantAccountIds(value) {
            const AppAcc = cs.GetAppAccFromParty(value)
            this.newContainer.applicantID = AppAcc.applicantid
            this.newContainer.accountID = AppAcc.accountid
        },
        handleFileUpload(event) {
            this.newContainer.file = event.target.files[0];
        },
        async ova_report() {
            console.log(this.newContainer.partyName)
            this.updateApplicantAccountIds(this.newContainer.partyName)
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                },
            };
            const response = await axios.get('/ova_report?applicant=' + this.newContainer.applicantID + '&account=' + this.newContainer.accountID + '&vessel=' + this.newContainer.vesselID + '&voyage=' + this.newContainer.VoyId + '&location=' + this.newContainer.locID + '&movement=' + this.newContainer.movementID, config);
            console.log(response)
            if (response.data[0] === 0) {
                useToast().error(response.data[1]);
            } else {
                useToast().success(response.data[1]);
            }
        }
    }
}
</script>

<style scoped>
/* Form group styling for form elements */
.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 15px;
}

.form-item {
    width: 30%;
}

select,
input[type="text"],
input[type="date"],
input[type="number"],
v-select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #FFF;
}

.excel-button {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}
</style>