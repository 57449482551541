<template>
  <div class="company-list">
    <h1>Company List</h1>

    <!-- Search Input -->
    <div class="search-container">
      <input type="text" v-model="searchQuery" @input="searchCompanies" placeholder="Search...">
      <button @click="clearSearch">Clear</button>
    </div>

    <div v-if="isLoading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>

    <div v-else>
      <!-- Table -->
      <table class="company-table">
        <thead>
          <tr>
            <th>Company ID</th>
            <th>Company Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>PAN</th>
            <th>GST</th>
            <th>TAN</th>
            <th>Active</th>
            <th v-if="hasPermissions">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in displayedCompanies" :key="company.companyid">
            <td>{{ company.companyid }}</td>
            <td>{{ company.companyname }}</td>
            <td>{{ company.company_address }}</td>
            <td>{{ company.company_city }}</td>
            <td>{{ company.company_state }}</td>
            <td>{{ company.company_country }}</td>
            <td>{{ company.company_pan }}</td>
            <td>{{ company.company_gst }}</td>
            <td>{{ company.company_tan }}</td>
            <td>{{ company.company_is_active }}</td>
            <td v-if="hasPermissions">
              <button @click="editCompany(company)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages || totalPages === 0">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CompanyList',
  data() {
    return {
      companies: [],
      displayedCompanies: [],
      isLoading: false,
      error: null,
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
      hasPermissions: false, // Assuming this is set based on user permissions
    };
  },
  created() {
    this.fetchCompanyList();
    this.checkPermissions(); // Check user permissions
  },
  methods: {
    async fetchCompanyList() {
      this.isLoading = true;
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          throw new Error('No token available');
        }

        const headers = {
          'accept': 'application/json',

          'Authorization': `Bearer ${token}`,
        };

        const response = await axios.get('/company_list/', { headers });
        this.companies = response.data[2];
        this.filterCompanies(); // Initial filtering and pagination
      } catch (error) {
        console.error('Error fetching company list:', error);
        this.error = 'Error fetching company list. Please try again later.';
      } finally {
        this.isLoading = false;
      }
    },
    checkPermissions() {
      // Replace with actual logic to check user permissions
      const token = localStorage.getItem('access_token');
      if (token) {
        // Example: Check if user has edit permissions
        // For demonstration purposes, assume permission is true
        this.hasPermissions = true;
      }
    },
    searchCompanies() {
      // Apply search filter as user types
      this.filterCompanies();
    },
    filterCompanies() {
  // Apply search filter
  let filteredCompanies = this.companies;

  if (this.searchQuery && this.companies && this.companies.length > 0) {
    const normalizedQuery = this.searchQuery.toLowerCase().trim();
    filteredCompanies = this.companies.filter(company =>
      (company.companyname && company.companyname.toLowerCase().includes(normalizedQuery)) ||
      (company.company_address && company.company_address.toLowerCase().includes(normalizedQuery)) ||
      (company.company_city && company.company_city.toLowerCase().includes(normalizedQuery)) ||
      (company.company_state && company.company_state.toLowerCase().includes(normalizedQuery)) ||
      (company.company_country && company.company_country.toLowerCase().includes(normalizedQuery)) ||
      (company.company_pan && company.company_pan.toLowerCase().includes(normalizedQuery)) ||
      (company.company_gst && company.company_gst.toLowerCase().includes(normalizedQuery)) ||
      (company.company_tan && company.company_tan.toLowerCase().includes(normalizedQuery))
    );
  }

  // Paginate the filtered results
  const startIndex = (this.currentPage - 1) * this.perPage;
  this.displayedCompanies = filteredCompanies.slice(startIndex, startIndex + this.perPage);
},

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.filterCompanies();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.filterCompanies();
      }
    },
    editCompany(company) {
      // Logic to handle editing the selected company
      alert(`Edit company ${company.companyname} with ID ${company.companyid}`);
    },
    clearSearch() {
      this.searchQuery = '';
      this.filterCompanies(); // Reset filter
    },
  },
  computed: {
    totalPages() {
      if (!this.displayedCompanies || !this.displayedCompanies.length) {
        return 0;
      }
      return Math.ceil(this.displayedCompanies.length / this.perPage);
    },
  },
};
</script>

<style scoped>
.company-list {
  padding: 20px;
}

.company-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.company-table th,
.company-table td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: left;
}

.search-container {
  margin-bottom: 10px;
}

.pagination {
  margin-top: 10px;
}

.pagination button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
