<template>
  <div class="main">
    <div class="transaction-list">
      <h1 class="title">Bills</h1>
      <div class="row">
        <div class="container">
          <!-- Form for filtering transactions -->
          <div class="form-group">
            <label for="partyname">Party *</label>
            <v-select :clearable="false" id="partyname" v-model="formData.partyname" :options="party" label="short_name"
              :reduce="party => party.autoid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="vesselName">Vessel *</label>
            <v-select :clearable="false" id="vesselName" v-model="formData.vesselName" :options="vessel" label="Name"
              :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="voyageName">Voyage *</label>
            <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage" label="Name"
              :reduce="voyage => voyage.Voyageid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="locationname">Location *</label>
            <v-select :clearable="false" v-model="formData.locationname" :options="location" label="Name"
              :reduce="location => location.locationid" selectOnTab="True" append-to-body />
          </div>
          <div class="form-group">
            <label for="movementname">Movement Type *</label>
            <v-select :clearable="false" id="movementname" v-model="formData.movementname" :options="movement" label="Name"
              :reduce="movement => movement.Movid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="fromdate">Report From Date *</label>
            <input type="date" id="fromdate" v-model="formData.fromdate"
              :max="new Date().toLocaleDateString('en-ca')" />
          </div>
          <div class="form-group">
            <label for="todate">Report To Date *</label>
            <input type="date" id="todate" v-model="formData.todate" :max="new Date().toLocaleDateString('en-ca')" />
          </div>
          <div class="form-group">
            <label for="reporttype">Report Type *</label>
            <select id="reporttype" v-model="formData.reporttype">
              <option value="0" selected>Select Report Type</option>
              <option value="1">Survey</option>
              <option value="2">Comparator</option>
              <option value="3">Non-Eor</option>
            </select>
          </div>
          <div class="form-group" style="width: 100%; display: flex; justify-content: space-between;">
            <div style="width: 20%;">
              <label for="contnumber">Container No</label>
              <input type="text" id="contnumber" v-model="formData.contnumber"
                placeholder="Generating Report for Specific Container(s)..." />
            </div>
            <div style="width: 20%; height: 20%;margin-top: 20px;margin-right: 10%;">
              <button type="button" class="excel-button" @click="getBills">Show Result</button>
              <button type="button" class="cancel-button1" @click="resetForm">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="controls">
        <div class="search-container">
          <input v-model="searchQuery" placeholder="Search..." />
          <i class="fas fa-search search-icon"></i>
        </div>
        <div class="actions">
          <button class="export-button" title="Export to CSV" @click="exportToCSV">
            <i class="fas fa-ellipsis-v"></i>
            <ul class="export-options">
              <li>Export to CSV</li>
            </ul>
          </button>
        </div>
      </div>

      <div v-if="isLoading">Loading transactions...</div>
      <div v-else-if="error">Error loading transactions: {{ error }}</div>

      <div v-else>
        <div class="table-container">
          <table class="transaction-table  table-danger">
            <thead>
              <tr>
                <th @click="sortByColumn('billno')">Bill No</th>
                <th @click="sortByColumn('billdate')">Bill Date</th>
                <th @click="sortByColumn('applicantname')">Applicant Name</th>
                <th @click="sortByColumn('accountname')">Account Name</th>
                <th @click="sortByColumn('companyid')">Company Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill) in paginatedBills" :key="bill.autoid">
                <td>{{ bill.billno }}</td>
                <td>{{ formatDate(bill.billdate) }}</td>
                <td>{{ bill.applicantname }}</td>
                <td>{{ bill.accountname }}</td>
                <td>{{ companyMap[bill.companyid] || 'Unknown' }}</td>
                <td>
                  <div v-if="bill.filename != null">
                    <button @click="openPDF(bill.filename)" class="pdf-button">
                      <i class="fas fa-file-pdf"></i> View PDF
                    </button>
                  </div>
                </td>
                <td>
                  <button @click="openEditModal(bill.billno)" class="edit-button">
                    <i class="fas fa-edit"></i> Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
              :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
          </div>
        </div>

        <div v-if="isModalVisible" class="edit-overlay">
          <div class="edit-overlay-content">
            <span class="close-btn" @click="closeModal">&times;</span>
            <h2>Container Inspection Report</h2>
            <div class="table-container">
              <table class="transaction-table">
                <thead>
                  <tr>
                    <th>Report no</th>
                    <th>Date</th>
                    <th>Clear</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="report in reports" :key="report.rptno">
                    <td>{{ report.rptno }}</td>
                    <td>{{ formatDate(report.rptdate) }}</td>
                    <td><button @click="clearReportFromBill(report.billno, report.rptno)"
                        class="edit-button">
                        <i class="fas fa-edit"></i> Clear
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Save and Cancel buttons -->
              <div class="form-buttons">
                <button type="button" class="cancel-button" @click="closeModal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      bills: [],
      companyMap: {},  // To map company IDs to names
      isLoading: false,
      error: null,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      party: JSON.parse(localStorage.getItem('party')),
      vessel: JSON.parse(localStorage.getItem('vessel')),
      voyage: JSON.parse(localStorage.getItem('voyage')),
      location: JSON.parse(localStorage.getItem('locations')),
      movement: JSON.parse(localStorage.getItem('movements')),
      rptdate: new Date().toLocaleDateString('en-ca'),
      formData: {
        applicantid: 0,
        accountid: 0,
        partyname: 0,
        vesselName: 0,
        voyageName: 0,
        locationname: 0,
        movementname: 0,
        fromdate: '',
        todate: '',
        contnumber: '',
        reporttype: 0
      },
      sortDirection: {
        billno: 'asc',
        billdate: 'asc',
        applicantname: 'asc',
        accountname: 'asc',
        companyid: 'asc',
      },
      reports:[],
      isModalVisible: false, // Control visibility of the modal
    };
  },
  computed: {
    filteredBills() {
      if (!this.bills) {
        return [];
      }
      return this.bills.filter(bill => {
        return Object.values(bill).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedBills() {
      if (!this.bills) {
        return [];
      }
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredBills.slice(start, end);
    },
    totalPages() {
      if (!this.bills) {
        return 0;
      }
      return Math.ceil(this.filteredBills.length / this.rowsPerPage);
    },
  },
  methods: {
    updateNewContainerIds(value) {
      const AppAcc = cs.GetAppAccFromParty(value)
      this.formData.applicantid = AppAcc.applicantid
      this.formData.accountid = AppAcc.accountid
    },
    async getBills() {
      this.isLoading = true;
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      this.updateNewContainerIds(this.formData.partyname)
      const transactionData = {
        applicant: this.formData.applicantid,
        account: this.formData.accountid,
        vessel: this.formData.vesselName,
        voyage: this.formData.voyageName,
        movement: this.formData.movementname,
        location: this.formData.locationname,
        fromdate: this.formData.fromdate,
        todate: this.formData.todate,
        contnumber: this.formData.contnumber
      }
      if (transactionData.location == "") {
        transactionData.location = 0;
      }
      try {
        const response = await axios.get('/all_bills?applicant=' + transactionData.applicant + '&account=' + transactionData.account + '&vessel=' + transactionData.vessel + '&voyage=' + transactionData.voyage + '&movement=' + transactionData.movement + '&location=' + transactionData.location + '&fromdate=' + transactionData.fromdate + '&todate=' + transactionData.todate + '&contnumber=' + transactionData.contnumber, config);
        console.log(response)
        this.bills = response.data[2];
        await this.loadCompanyList();  // Load company names after fetching bills
      } catch (error) {
        console.error('Error fetching bills:', error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    async loadCompanyList() {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get('/company_list', config);
        const companies = response.data[2];
        this.companyMap = companies.reduce((map, company) => {
          map[company.companyid] = company.companyname;
          return map;
        }, {});
      } catch (error) {
        console.error('Error fetching company list:', error);
        this.error = error.message;
      }
    },
    exportToCSV() {
      const headers = [
        "Bill No", "Bill Date", "Applicant Name", "Account Name", "Company Name"
      ];

      const csvContent = [
        headers.join(","),
        ...this.filteredBills.map(bill =>
          headers.map(header => JSON.stringify(bill[header.toLowerCase()] || '')).join(",")
        )
      ].join("\n");

      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "bills.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async getReportDetails(billno) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get('/get_reports_by_billno?billno=' + billno, config);
        //console.log(response.data[1], response.data[2]);
        console.log(response)
        this.reports = response.data[2];
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async openEditModal(billno) {
      this.getReportDetails(billno)
      this.isModalVisible = true; // Show the modal
    },
    closeModal() {
      this.isModalVisible = false; // Hide the modal
      //this.isAddModalVisible = false;
      this.reports = null; // Reset selected transaction
    },
    
    async clearReportFromBill(billno, rptno) {
      alert(billno)
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get('/clear_report_from_bill?rptno=' + rptno + '&billno=' + billno, config);
        console.log(response)
        useToast().success(response.data[1]);
        this.getReportDetails(billno)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async openPDF(filename) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.get('/ConvertImageToBlob?filename=' + filename, config, { responseType: 'blob' });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(pdfBlob);
      window.open(url);
    },
  },
  mounted() {
    this.getBills(); // Fetch bills on component mount
  }
};
</script>
<style scoped>
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

}

.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  width: 22%;
  padding: 5px;
  float: left;
}

label {
  display: block;
  margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input::placeholder {
  color: #888;
}

.excel-button,
.generate-button,
.cancel-button1 {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.excel-button {
  background-color: #17a2b8;
}

.cancel-button1 {
  background-color: #dc3545;
}

button:hover {
  opacity: 0.9;
}

.date-range {
  display: flex;
  justify-content: space-between;
}

.date-range label {
  flex: 1;
  margin: 0 10px;
}

.date-range input {
  flex: 2;
}

.print-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.print-button:hover,
.export-button:hover {
  background-color: rgb(30, 60, 150);
}

.export-options {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.export-button:hover .export-options {
  display: block;
}

.export-options li {
  padding: 8px 12px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #f1f1f1;
}

.table-container {
  overflow-x: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.transaction-table th,
.transaction-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  cursor: pointer;
  background-color: #f4f4f4;
}

.transaction-table tr:hover {
  background-color: #f9f9f9;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rows-per-table {
  display: flex;
}

.rows-per-table button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
}

.rows-per-table button.active {
  background-color: #007bff;
  color: white;
}

.pagination {
  display: flex;
}

.page-number {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.page-number.active {
  background-color: #007bff;
  color: white;
}
/* Overlay container */
.edit-overlay {
  position: fixed;
  /* Ensures the overlay stays above all other content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* Enables scrolling if the content exceeds viewport height */
  z-index: 9999;
  /* Ensures it appears above all other content */
}

/* Overlay content */
.edit-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  /* Adjusted width for better fit */
  /*max-width: 1000px;*/
  /* Adjusted max-width */
  max-height: 90vh;
  /* Limits the height to prevent overflow */
  overflow-y: auto;
  /* Enables vertical scrolling within the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 50px;
  /* Adds margin from the top */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

</style>
