<template>
  <div class="main">
    <div class="body-content">
      <div v-if="isLoading">Loading Location list...</div>
      <div v-else-if="error">Error loading Location list: {{ error }}</div>
      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Location List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Location">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Location</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="location-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Location Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Name')"></i>
                </th>
                <th>
                  <span>Short Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ShortName')"></i>
                </th>
                <th>
                  <span>Is Yard</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isArea')"></i>
                </th>
                <th>
                  <span>Location Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('LocationCode')"></i>
                </th>
                <th>
                  <span>IGM Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('igmCode')"></i>
                </th>
                <th>
                  <span>ZIM Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ZIMCode')"></i>
                </th>
                <th>
                  <span>YML Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('YMLCode')"></i>
                </th>
                <th>
                  <span>OVA Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('OVA_Code')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newLocation.Name" placeholder="Location Name" />
                </td>
                <td><input v-model="newLocation.ShortName" placeholder="Short Name" /></td>
                <td>
                  <input type="checkbox" v-model="newLocation.isArea" />
                </td>
                <td><input v-model="newLocation.LocationCode" placeholder="Location Code" /></td>
                <td><input v-model="newLocation.igmCode" placeholder="IGM Code" /></td>
                <td><input v-model="newLocation.ZIMCode" placeholder="ZIM Code" /></td>
                <td><input v-model="newLocation.YMLCode" placeholder="YML Code" /></td>
                <td><input v-model="newLocation.OVA_Code" placeholder="OVA Code" /></td>
                <td v-if="hasPermissions">
                  <button @click="saveLocation" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="(location) in paginatedLocations" :key="location.locationid"
                :class="{ 'editing': location === selectedLocation }">
                <td v-if="location === selectedLocation">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newLocation.Name" placeholder="Location Name" />
                </td>
                <td v-else>{{ location.Name }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.ShortName" placeholder="Short Name" />
                </td>
                <td v-else>{{ location.ShortName }}</td>
                <td v-if="location === selectedLocation">
                  <input type="checkbox" v-model="newLocation.isArea" />
                </td>
                <td v-else>{{ location.isArea ? 'Yes' : 'No' }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.LocationCode" placeholder="Location Code" />
                </td>
                <td v-else>{{ location.LocationCode }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.igmCode" placeholder="IGM Code" />
                </td>
                <td v-else>{{ sanitizeField(location.igmCode) }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.ZIMCode" placeholder="ZIM Code" />
                </td>
                <td v-else>{{ sanitizeField(location.ZIMCode) }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.YMLCode" placeholder="YML Code" />
                </td>
                <td v-else>{{ sanitizeField(location.YMLCode) }}</td>
                <td v-if="location === selectedLocation">
                  <input v-model="newLocation.OVA_Code" placeholder="OVA Code" />
                </td>
                <td v-else>{{ sanitizeField(location.OVA_Code) }}</td>
                <td v-if="hasPermissions">
                  <button v-if="location === selectedLocation" @click="saveLocation(location)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="location === selectedLocation" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editLocation(location)" class="edit-button"><i
                      class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      locations: [],
      isLoading: false,
      error: null,
      nameError: '',
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedLocation: null,
      showExportOptions: false,
      newLocation: {
        locationid: 0,
        Name: '',
        ShortName: '',
        isArea: false,
        LocationCode: '',
        igmCode: '',
        ZIMCode: '',
        YMLCode: '',
        OVA_Code: '',
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      },
      sortDirection: {
        Name: 'asc',
        ShortName: 'asc',
        isArea: 'asc',
        LocationCode: 'asc',
        igmCode: 'asc',
        ZIMCode: 'asc',
        YMLCode: 'asc',
        OVA_Code: 'asc',
      },
      showAddTooltip: false,
    };
  },
  watch: {
    'newLocation.Name'(newName) {
    if (this.isAddingNew || (this.selectedLocation && this.newLocation.Name !== this.selectedLocation.Name)) {
      this.checkLocationName(newName);
    }
  },
  searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
  },
  created() {
    this.fetchLocations();
    this.checkPermissions();
  },
  computed: {
    filteredLocations() {
      return this.locations.filter(location => {
        return Object.values(location).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedLocations() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredLocations.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredLocations.length / this.rowsPerPage);
    },
    paginatedPages() {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage; // assuming you have a currentPage data property
    const maxPagesToShow = 10;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they are fewer than the maxPagesToShow
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Always show the first page
      pages.push(1);

      // Show a range around the current page
      let start = Math.max(2, currentPage - 4);
      let end = Math.min(totalPages - 1, currentPage + 4);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  },
  showPrevious() {
    return this.currentPage > 1;
  },
  showNext() {
    return this.currentPage < this.totalPages;
  }
  },
  methods: {
    goToPage(page) {
    if (page === '...' || page === this.currentPage) return;
    this.currentPage = page;
  },
  sanitizeField(value) {
    return value === null || value === 'NULL' ? '' : value;
  },
    async checkLocationName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const locationExists = this.locations.some(location => location.Name.toLowerCase() === name.toLowerCase());
        if (locationExists) {
          this.nameError = 'Location name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking location name:', err);
      }
    },
    async fetchLocations() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/location_list', config);
        this.locations = response.data[2];
        localStorage.setItem('locations', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching location list:', error);
        this.error = 'Error fetching location list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    checkPermissions() {
      const token = localStorage.getItem('access_token');
      this.hasPermissions = !!token; // Assuming that having a token means permissions are granted
    },
    openAddOverlay() {
      if (this.selectedLocation) {
        this.cancelEdit(); // Cancel editing if a location is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewLocation(); // Reset new location fields
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedLocation = null;
    },
    async saveLocation() {
      const toast = useToast();
      try {
        if (this.nameError) {
          console.error('Name error:', this.nameError);
          toast.error('Please fix the errors before saving.');
          return;
        }

        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No token found in localStorage');
          return;
        }

        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        if (!this.selectedLocation) {
          // Set additional fields for new location
          this.newLocation.CreateDate = new Date().toISOString();
          this.newLocation.ModiDate = new Date().toISOString();
          this.newLocation.created_by = this.currentUserId; // Assuming `currentUserId` is set elsewhere
          this.newLocation.last_modified_by = this.currentUserId; // Assuming `currentUserId` is set elsewhere
        }
        this.newLocation.isDeletable = Boolean(this.newLocation.isDeletable);
        if (this.selectedLocation) {
          // Update existing location
          await axios.put(`/update_location/${this.selectedLocation.locationid}/`, this.newLocation, config);
          toast.success('Location Updated Successfully!');
        } else {
          // Create new location
          await axios.post('/create_location', this.newLocation, config);
          toast.success('Location Created Successfully!');
        }

        this.isAddingNew = false;
        this.selectedLocation = null;
        this.fetchLocations();
      } catch (error) {
        console.error('Error saving location:', error);
        this.error = 'Error saving location. Check console for details.';
      }
    },
    resetNewLocation() {
      this.newLocation = {
        locationid: 0,
        Name: '',
        ShortName: '',
        isArea: false,
        LocationCode: '',
        igmCode: '',
        ZIMCode: '',
        YMLCode: '',
        OVA_Code: '',
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
    sortByColumn(column) {
      const currentDirection = this.sortDirection[column];
      const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
      this.sortDirection[column] = newDirection;
      this.locations.sort((a, b) => {
        if (a[column] < b[column]) return newDirection === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return newDirection === 'asc' ? 1 : -1;
        return 0;
      });
    },
    editLocation(location) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedLocation = location;
      this.newLocation = { ...location };
    },
    async cancelEdit() {
      this.selectedLocation = null;
      this.resetNewLocation();
    },
    exportToCSV() {
      if (this.filteredLocations) {
        const csvData = this.filteredLocations.map(location => [
          location.Name,
          location.ShortName,
          location.isArea,
          location.LocationCode,
          location.igmCode,
          location.ZIMCode,
          location.YMLCode,
          location.OVA_Code,
        ]);

        const csvContent = "data:text/csv;charset=utf-8," + csvData.map(row => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "location_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}



.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.location-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  color: #d9534f; /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px; /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5; /* Light red background */
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button,
.save-button,
.cancel-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: block;
  top: 20%;
  right: 1%;
  
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.location-table {
  width: 100%;
  border-collapse: collapse;
}

.location-table th,
.location-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.location-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.location-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}
.export-container {
  position: relative;
  /* Add this */
}


.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}
</style>

