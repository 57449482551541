<template>
  <div id="app" @click="handleAppClick">
    <div class="top-navbar">
      <div class="navbar-left">
        <button v-if="isAuthenticated" class="toggle-button" @click="toggleSidebar">
          <i class="fas fa-bars"></i>
        </button>
        <div class="app-title">
          <div class="main-title">Container Inspection and Tracking System</div>
          <div class="sub-title">by FiSYS Technology</div>
        </div>
      </div>
      <div class="navbar-right">
        <div class="navigation-button-custom dropdown">
          <button v-if="isAuthenticated" @click="toggleProfileMenu" class="profile-button">
            <i class="fas fa-user"></i>
          </button>
          <div class="sub-title">Lardner &amp; North</div>
          <div v-if="showProfileMenu" class="profile-dropdown" ref="profileDropdown">
            <button @click="changePassword">User Name</button>
            <button @click="changePassword">Change Password</button>
          </div>
        </div>
        <div class="profile-menu" v-if="isAuthenticated">

          <button @click="logoutUser" class="logout-button">
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="main-container">
      <div v-if="isAuthenticated" class="sidebar" :class="{ 'sidebar-closed': !isSidebarOpen }">
        <div class="sidebar-content">
          <ul class="sidebar-menu">
            <li @click="navigateTo('dashboard')" class="dashboard-item">
              <i class="sidebar-icon fas fa-home"></i> <!-- Replace with homeLogo icon -->
              <span class="menu-name">Dashboard</span>
            </li>
            <!-- Group groupmaster -->
            <li v-for="(itemx, indexx) in groupmaster" :key="itemx.auto_id" @click="selectMenuItem(itemx)"
              :class="{ 'last-item': indexx === groupmaster.length - 1 }">
              <i class="sidebar-icon" :class="[itemx.menu_icon]"></i>
              <span class="menu-name">{{ itemx.menu_name }}</span>
              <ul v-if="itemx.menu_name === 'Transaction' && this.showSubmenu" class="submenu"
                :class="{ show: showSubmenu }">
                <li v-for="(item, index) in group3" :key="item.auto_id" @click="selectMenuItem(item)"
                  :class="{ 'last-item': index === group3.length - 1 }">
                  <i class="sidebar-icon" :class="[item.menu_icon]"></i>
                  <span class="menu-name">{{ item.menu_name }}</span>
                </li>
              </ul>
              <ul v-if="itemx.menu_name === 'Upload' && this.showSubmenu1" class="submenu1"
                :class="{ show: showSubmenu1 }">
                <li v-for="(item, index) in group2" :key="item.auto_id" @click="selectMenuItem(item)"
                  :class="{ 'last-item': index === group2.length - 1 }">
                  <i class="sidebar-icon" :class="[item.menu_icon]"></i>
                  <span class="menu-name">{{ item.menu_name }}</span>
                </li>
              </ul>
              <ul v-if="itemx.menu_name === 'Report' && this.showSubmenu2" class="submenu2"
                :class="{ show: showSubmenu2 }">
                <li v-for="(item, index) in group4" :key="item.auto_id" @click="selectMenuItem(item)"
                  :class="{ 'last-item': index === group4.length - 1 }">
                  <i class="sidebar-icon" :class="[item.menu_icon]"></i>
                  <span class="menu-name">{{ item.menu_name }}</span>
                </li>
              </ul>
              <ul v-if="itemx.menu_name === 'Bill' && this.showSubmenu4" class="submenu4"
                :class="{ show: showSubmenu4 }">
                <li v-for="(item, index) in group5" :key="item.auto_id" @click="selectMenuItem(item)"
                  :class="{ 'last-item': index === group5.length - 1 }">
                  <i class="sidebar-icon" :class="[item.menu_icon]"></i>
                  <span class="menu-name">{{ item.menu_name }}</span>
                </li>
              </ul>
              <ul v-if="itemx.menu_name === 'Master' && this.showSubmenu3" class="submenu3"
                :class="{ show: showSubmenu3 }">
                <li v-for="(item, index) in group1" :key="item.auto_id" @click="selectMenuItem(item)"
                  :class="{ 'last-item': index === group1.length - 1 }">
                  <i class="sidebar-icon" :class="[item.menu_icon]"></i>
                  <span class="menu-name">{{ item.menu_name }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      showProfileMenu: false,
      isSidebarOpen: true,
      menuItems: [],
      isAuthenticated: false,
      showSubmenu: false,
      showSubmenu1: false,
      showSubmenu2: false,
      showSubmenu3: false,
      showSubmenu4: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    username() {
      return this.user.name;
    },
    groupmaster() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(0)
      );
    },
    group1() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(1)
      );
    },
    group2() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(2)
      );
    },
    group3() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(3)
      );
    },
    group4() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(4)
      );
    },
    group5() {
      return this.menuItems.filter(item =>
        [item.parent_id].includes(32)
      );
    },
  },
  created() {
    this.checkAuthentication();
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['savePermissions', 'logout']),
    async checkAuthentication() {
      const token = localStorage.getItem('access_token');
      //console.log(token)
      this.isAuthenticated = !!token;
      const decodedToken = this.decodeAccessToken(token);
      //console.log(decodedToken);
      const exp = decodedToken.payload.exp;
      if (Date.now() >= exp * 1000) {
        //console.log(decodedToken);
        this.logoutUser();
      } else {
        //return expired
        if (this.isAuthenticated) {
          await this.fetchMenuItems();
        }
      }
    },
    decodeAccessToken(token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid token format');
      }
      return {
        header: JSON.parse(atob(parts[0])),
        payload: JSON.parse(atob(parts[1])),
        signature: parts[2],
      };
    },
    async fetchMenuItems() {
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'accept': 'application/json',
            "Authorization": `Bearer ${token}`,
          },
        };
        const response = await axios.get('/menu', config);
        //console.log(response.data[2]);
        this.menuItems = response.data[2];
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },
    selectMenuItem(item) {
      this.showSubmenu = false;
      this.showSubmenu1 = false;
      this.showSubmenu2 = false;
      this.showSubmenu3 = false;
      this.showSubmenu4 = false;
      if (item.menu_name === 'Transaction') {
        this.isSidebarOpen = true;
        this.showSubmenu = !this.showSubmenu;
      } else if (item.menu_name === 'Upload') {
        this.isSidebarOpen = true;
        this.showSubmenu1 = !this.showSubmenu1;
      } else if (item.menu_name === 'Report') {
        this.isSidebarOpen = true;
        this.showSubmenu2 = !this.showSubmenu2;
      } else if (item.menu_name === 'Master') {
        this.isSidebarOpen = true;
        this.showSubmenu3 = !this.showSubmenu3;
      } else if (item.menu_name === 'Bill') {
        this.isSidebarOpen = true;
        this.showSubmenu4 = !this.showSubmenu4;
      } else {
        const routeName = item.menu_action
          .toLowerCase()                  // Convert to lowercase
          .replace(/\s+/g, '')            // Remove spaces
          .replace(/[^\w]+/g, '');
        try {
          console.log(routeName);
          this.$router.push({ name: routeName })
          this.savePermissions(item);
        } catch (err) {
          /* if (err.name === 'NavigationDuplicated') {
            console.log('Tried to navigate to the same route');
          } else if (err.name === 'Error') {
            // If the route is not found, redirect to another page
            console.warn(`Route "${routeName}" not found. Redirecting...`);
            this.$router.push({ name: 'not-found' }); // Replace 'not-found' with the desired fallback route
          } */
          this.$router.push({ name: 'notfound' })

        }
        //this.$router.push({ name: routeName });
      }
      localStorage.setItem('permissions', JSON.stringify(item));
    },
    toggleProfileMenu() {
      this.showProfileMenu = true;
    },
    changePassword() {
      this.$router.push('/change-password');
      this.closeProfileMenu();
    },
    logoutUser() {
      this.logout();
      this.closeProfileMenu();
    },
    closeProfileMenu() {
      this.showProfileMenu = false;
    },
    handleClickOutside(event) {
      if (
        this.showProfileMenu &&
        !this.$refs.profileDropdown.contains(event.target) &&
        event.target.closest('.profile-button') === null
      ) {
        this.closeProfileMenu();
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$primary-color: #8697C4;
$secondary-color: #f0f0f0;
$text-color: #333;
$sidebar-bg: #f5f5f5;
$sidebar-text-color: #333;
$sidebar-hover-bg: #e9e9e9;
$sidebar-hover-text-color: #333;
$sidebar-width: 200px;
$mobile-breakpoint: 768px; // Adjust this value based on your design

body {
  margin: 0px;
  background-color: #f0f2f5;
  overflow-y: hidden;
  //font-family: 'Roboto', sans-serif;
  //font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-optical-sizing: auto;
  font-style: normal;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

}


.dropdown {
  position: relative;
  display: inline-block;
}

.navigation-button-custom {
  /*position: absolute;
    top: 0;
    z-index: 9999;
    right: 50px;*/
  margin-top: -20px;
  border-radius: 0 0 10px 10px;
  padding: 9px 18px 9px 19px;
  background-color: #3D52A0;
  display: flex;
  margin-right: 20px;
  box-shadow: 2px 2px #3D52DB;
  //box-shadow: 5px 10px #888888;
}

.profile-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: darken($primary-color, 10%);
  }
}

.profile-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  color: $text-color;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
  z-index: 1001;

  button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: white;
    color: $text-color;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: $secondary-color;
    }
  }
}

.top-navbar {
  background-color: $primary-color;
  color: white;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;

  .navbar-left {
    display: flex;
    align-items: center;

    .toggle-button {
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 24px;
      margin-right: 10px;
    }

    .app-title {
      .main-title {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
      }

      .sub-title {
        font-size: 14px;
        font-weight: normal;
        margin-left: 10px;
      }
    }
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .profile-menu {
      position: relative;
      display: flex;
      align-items: center;

      .logout-button {
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        padding: 5px 10px;
        margin-right: 25px;

        &:hover {
          color: darken($primary-color, 10%);
        }
      }
    }
  }
}

.main-container {
  display: flex;
  height: calc(100vh - 60px); // Subtract the height of the top navbar
}

.sidebar {
  width: 200px;
  /* Fixed width */
  height: 100%;
  background-color: $sidebar-bg;
  padding: 5px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid rgb(231, 234, 235);
  max-height: 100%;


  &.sidebar-closed {
    width: 50px;

    .menu-name {
      display: none;
    }
  }

  .sidebar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 500;

    >li {
      cursor: pointer;
      padding: 10px 0px 10px 10px;
      display: flex;
      flex-direction: column; // Stack submenu and main menu vertically
      align-items: flex-start; // Align items to the left
      transition: background-color 0.3s ease;
      width: 100%;
      border-radius: 4px;
      color: $sidebar-text-color;
      position: relative;

      &:hover {
        background-color: $sidebar-hover-bg;
        color: $sidebar-hover-text-color;
      }

      .sidebar-icon {
        font-size: 18px;
        margin-right: 12px;
      }

      .menu-name {
        font-size: 15px;
      }

      // Submenu styles
      ul {
        list-style: none;
        padding-left: 10px; // Indent submenu items
        display: none; // Initially hide the submenu

        &.show {
          display: block; // Show the submenu when toggled
        }

        li {
          padding: 8px 0; // Padding for submenu items
          color: $text-color; // Text color for submenu items

          &:hover {
            background-color: $secondary-color; // Change background on hover
          }
        }
      }
    }

    .last-item {
      border-bottom: 1px solid #ddd;
      margin-bottom: 1px;
    }

    .separator {
      height: 1px;
      background-color: #ddd;
      margin: 0;
    }
  }
}

.main-content {
  flex: 1;
  padding: 0px;
  margin: 0px;
  transition: filter 0.3s ease;
  height: 100%;
  overflow-y: auto;
}

.main {
  background-color: #f0f2f5;
  border-radius: 0px;
}

.body-content {
  padding: 10px;
  //font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  display: flex;
  font-size: 30px;
  font-weight: 600;
  color: #333;
  margin-top: 0px;
  margin-bottom: 0px;
}

.export-options {
  display: block;
  top: 20%;
  right: 1%;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  min-height: 320px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

// Additional styles for grid, buttons, tables, etc. remain unchanged
.grid-design {
  padding: 0px 20px 0px 20px;
  font-size: 14px;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  color: #d9534f;
  /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px;
  /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  /* Light red background */
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-container {
  position: relative;
  /* Add this */
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  position: relative;
  height: 32px;
  margin-left: 10px;
  /* Space between buttons */
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  top: 100%;
  right: 0;
  border-radius: 10px;
}

.export-button:hover .export-options {
  display: block;
}

.table-design {
  width: 100%;
  border-collapse: collapse;
}

.table-design th,
.table-design td {
  min-width: 60px;
  padding: 0px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-design th {
  font-weight: bold;
}

.table-design th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}


.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.field-60 {
  width: 60px
}

.field-70 {
  width: 70px
}

.field-80 {
  width: 80px
}

.field-90 {
  width: 90px
}

.field-100 {
  width: 100px
}

.field-110 {
  width: 110px
}

.field-120 {
  width: 120px
}

input,
select {
  padding: 9px 2px;
  font-size: 14px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Ensure v-select placeholder is visible */
.v-select .vs__placeholder {
  /* color: #aaa;*/
  /* Change placeholder color if needed */
  overflow: visible;
  /* Ensure placeholder is visible if content overflows */
}

.v-select {
  /* color: #aaa;*/
  /* Change placeholder color if needed */
  overflow: visible;
  /* Ensure placeholder is visible if content overflows */
  background-color: #fff;
  flex-wrap: nowrap;
}

.vs__search {
  padding: 0px;
}

.vs__selected-options {
  flex-wrap: nowrap;

}
</style>