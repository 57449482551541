<template>
  <div class="upload-content-tile">
    <h1 class="title">Upload Content</h1>

    <div class="form-row">
      <label for="selectedShortName" class="form-label">Party Name</label>
      <v-select :clearable="false" id="selectedShortName" v-model="formData.selectedShortName" :options="party"
        label="short_name" :reduce="party => party.autoid" selectOnTab="True" />
    </div>
    <div class="form-row">
      <label for="location" class="form-label">Location</label>
      <select id="location" v-model="formData.location" class="custom-select">
        <option value="0">Select Location</option>
        <option v-for="location in locations" :key="location.locationid" :value="location.locationid">
          {{ location.Name }}
        </option>
      </select>
    </div>

    <div class="form-row">
      <label for="vesselName" class="form-label">Vessel Name</label>
      <v-select :clearable="false" id="vesselName" v-model="formData.vesselName" ref="vesselName" :options="vessel"
        label="Name" :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
    </div>

    <div class="form-row">
      <label for="voyageName" class="form-label">Voyage Name</label>
      <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage" label="Name"
        :reduce="voyage => voyage.Voyageid" selectOnTab="True" />
    </div>
    <div class="form-row">
      <label for="uploadtype" class="form-label">Upload Type</label>
      <div>
        <input type="radio" id="advance" value="1" v-model="formData.uploadtype">
        <label for="advance">Advance Upload</label>
        <input type="radio" id="igm" value="2" v-model="formData.uploadtype">
        <label for="igm">IGM Upload</label>
      </div>
    </div>

    <div class="form-row">
      <label for="file" class="form-label">Select File</label>
      <input type="file" id="file" @change="handleFileUpload" class="custom-file-input">
    </div>

    <div class="form-actions" align="center">
      <button @click="uploadFile" style="margin-right: 10px;">Upload File</button>
      <button @click="DownloadFile">Download List</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'
import { saveAs } from 'file-saver';

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      party: JSON.parse(localStorage.getItem('party')),
      vessel: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      locations: [],
      formData: {
        selectedShortName: 0,
        applicantName: 0,
        accountName: 0,
        location: 0,
        vesselName: 0,
        voyageName: 0,
        uploadtype: '',
        file: null,
      },
    }
  },
  created() {
    this.fetchMasterLocations();
  },
  methods: {
    async fetchMasterLocations() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/master_location_list/', config);
        this.locations = response.data[2];
      } catch (error) {
        console.error('Error fetching Master Locations:', error);
      }
    },
    handleFileUpload(event) {
      this.formData.file = event.target.files[0];
    },
    async uploadFile() {
      this.updateApplicantAccountIds(this.formData.selectedShortName);
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'
        }
      }
      const uploadData = new FormData();
      uploadData.append('applicantName', this.formData.applicantName);
      uploadData.append('accountName', this.formData.accountName);
      uploadData.append('location', this.formData.location);
      uploadData.append('vesselName', this.formData.vesselName);
      uploadData.append('voyageName', this.formData.voyageName);
      uploadData.append('uploadtype', this.formData.uploadtype);
      uploadData.append('file', this.formData.file);
      try {
        const response = await axios.post('/upload_import?applicantid=' + this.formData.applicantName + '&accountid=' + this.formData.accountName + '&locationid=' + this.formData.location + '&vessselid=' + this.formData.vesselName + '&voyageid=' + this.formData.voyageName + '&uploadtype=' + this.formData.uploadtype, uploadData, config);
        //console.log('File uploaded successfully:', response.data);
        if (response.data[0] == "0") {
          useToast().error(response.data[1]);
        } else {
          useToast().success('File uploaded successfully');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async DownloadFile() {
      this.updateApplicantAccountIds(this.formData.selectedShortName);
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the headers
        },
      };
      const uploadData = new FormData();
      uploadData.append('applicantName', this.formData.applicantName);
      uploadData.append('accountName', this.formData.accountName);
      uploadData.append('location', this.formData.location);
      uploadData.append('vesselName', this.formData.vesselName);
      uploadData.append('voyageName', this.formData.voyageName);

      try {
        const response = await axios.post('/downLoad_import_list?applicantid=' + this.formData.applicantName + '&accountid=' + this.formData.accountName + '&locationid=' + this.formData.location + '&vessselid=' + this.formData.vesselName + '&voyageid=' + this.formData.voyageName, config, { responseType: 'blob' });
        console.log(response)
        if (response.data[0] === 0) {
          useToast().error(response.data[1]);
        } else {
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          saveAs(blob);
          //res(response.data);
          //useToast().success(response.data);
          //this.htmlContent = response.data;
          //this.resetNewContainer();
        }
        //console.log('File uploaded successfully:', response.data);
        //const url = URL.createObjectURL(new Blob([response.data], {
        //  type: 'application/vnd.ms-excel'
        //}));
        //window.open(url);
        //if (response.data[0] == "0") {
        //  useToast().error(response.data[1]);
        //} else {
        //  useToast().success('File uploaded successfully');
        //}
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    handleChange(event) {
      console.log(event.target.value)
      const selectedShortName = event.target.value;
      this.updateApplicantAccountIds(selectedShortName);
    },
    updateApplicantAccountIds(value) {
      const AppAcc = cs.GetAppAccFromParty(value)
      this.formData.applicantName = AppAcc.applicantid
      this.formData.accountName = AppAcc.accountid
    },
  },
};
</script>

<style scoped>
.upload-content-tile h1 {
  color: #343a40;
  font-size: 28px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.form-row {
  margin-bottom: 20px;
  display: flex;
  margin-left: 25%;
  align-items: center;
}

.form-row label {
  font-weight: bold;
  margin-right: 10px;
  width: 200px;
  /* Adjust width as needed */
  color: #495057;
}

select,
v-select {
  width: 150px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #FFF;
}

.custom-file-input {
  width: 100%;
  max-width: 300px;
  /* Adjust the width to match the select box */
  padding: 8px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
}

.form-actions {
  margin-top: 30px;
}

.form-actions button {
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.form-actions button:hover {
  background-color: #0056b3;
}
</style>