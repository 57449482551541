<template>
  <div class="main">
    <div class="body-content">
      <div v-if="isLoading">Loading Port list...</div>
      <div v-else-if="error">Error loading Port list: {{ error }}</div>
      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Port List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Port" :disabled="selectedPort">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Port</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="table-container">
          <table class="port-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Port Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('name')"></i>
                </th>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('description')"></i>
                </th>
                <th>
                  <span>Port Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('PortCode')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newPort.name" placeholder="Port Name" />
                </td>
                <td><input v-model="newPort.description" placeholder="Description" /></td>
                <td><input v-model="newPort.PortCode" placeholder="Port Code" /></td>
                <td v-if="hasPermissions">
                  <button @click="savePort" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr class="new-row" v-for="(port) in paginatedPorts" :key="port.portid"
                :class="{ 'editing': port === selectedPort }">
                <td v-if="port === selectedPort">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newPort.name" placeholder="Port Name" />
                </td>
                <td v-else>{{ port.name }}</td>
                <td v-if="port === selectedPort">
                  <input v-model="newPort.description" placeholder="Description" />
                </td>
                <td v-else>{{ port.description }}</td>
                <td v-if="port === selectedPort">
                  <input v-model="newPort.PortCode" placeholder="Port Code" />
                </td>
                <td v-else>{{ port.PortCode }}</td>
                <td v-if="hasPermissions">
                  <button v-if="port === selectedPort" @click="savePort(port)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="port === selectedPort" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editPort(port)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      ports: [],
      nameError: '',
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedPort: null,
      showExportOptions: false,
      newPort: {
        portid: 0,
        name: '',
        description: '',
        PortCode: '',
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      },
      sortDirection: {
        name: 'asc',
        description: 'asc',
        PortCode: 'asc',
      },
      showAddTooltip: false,
    };
  },
  created() {
    this.fetchPorts();
    this.checkPermissions();
  },
  computed: {
    resetPort() {
      return {
        portid: 0,
        name: '',
        description: '',
        PortCode: '',
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    filteredPorts() {
      return this.ports.filter(port => {
        return Object.values(port).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedPorts() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredPorts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPorts.length / this.rowsPerPage);
    },
    paginatedPages() {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage; // assuming you have a currentPage data property
    const maxPagesToShow = 10;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they are fewer than the maxPagesToShow
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Always show the first page
      pages.push(1);

      // Show a range around the current page
      let start = Math.max(2, currentPage - 4);
      let end = Math.min(totalPages - 1, currentPage + 4);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  },
  showPrevious() {
    return this.currentPage > 1;
  },
  showNext() {
    return this.currentPage < this.totalPages;
  }
  },
  watch: {
  'newPort.name'(newName) {
    if (this.isAddingNew || (this.selectedPort && this.newPort.name !== this.selectedPort.name)) {
      this.checkPortName(newName);
    }
  },
  searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
},

  methods: {
    goToPage(page) {
    if (page === '...' || page === this.currentPage) return;
    this.currentPage = page;
  },
    showTooltip() {
      this.showAddTooltip = true;
    },
    hideTooltip() {
      this.showAddTooltip = false;
    },
    async checkPortName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const portExists = this.ports.some(port => port.name.toLowerCase() === name.toLowerCase()&&
        port.portid !== this.newPort.portid);
        if (portExists) {
          this.nameError = 'Port name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking port name:', err);
      }
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async fetchPorts() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/port', config);
        this.ports = response.data[2];
        localStorage.setItem('ports', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching port list:', error);
        this.error = 'Error fetching port list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async checkPermissions() {
      this.hasPermissions = true; // Set this according to your logic
    },
    openAddOverlay() {
      if (this.selectedPort) {
        this.cancelEdit(); // Cancel editing if a port is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewPort(); // Reset new port fields
    },
    async savePort() {
      const toast = useToast();
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      const token = localStorage.getItem('access_token');
      const userId = this.getCurrentUserId();
      const now = new Date().toISOString();

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const payload = {
          ...this.newPort,
          created_by: userId,
          last_modified_by: userId,
          CreateDate: now,
          ModiDate: now,
        };

        if (this.isAddingNew) {
          await axios.post('/create_port', payload, config);
          toast.success('Port Created Successfully!');
        } else if (this.selectedPort) {
          await axios.put(`/update_port/${this.selectedPort.portid}`, payload, config);
          toast.success('Port Updated Successfully!');
        }

        this.fetchPorts();
        this.isAddingNew = false;
        this.selectedPort = null;
        this.newPort = this.resetPort;
      } catch (error) {
        console.error('Error saving port:', error);
        toast.error('Error saving port. Check console for details.');
      }
    },
    resetNewPort() {
      this.newPort = {
        portid: 0,
        name: '',
        description: '',
        PortCode: '',
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedPort = null;
    },
    editPort(port) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedPort = port;
      this.newPort = { ...port };
    },
    async cancelEdit() {
      this.selectedPort = null;
      this.resetNewPort();
    },
    exportToCSV() {
      const csvContent = [
        ['Port Name', 'Description', 'Port Code'],
        ...this.ports.map(port => [port.name, port.description, port.PortCode]),
      ].map(e => e.join(",")).join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'port_list.csv';
      link.click();
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
  },
};
</script>

<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}



.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.port-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}
.export-options {
  display: block;
  top: 20%;
  right: 1%;
  
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}


.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.port-table {
  width: 100%;
  border-collapse: collapse;
}

.port-table th,
.port-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.port-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.port-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}
.export-container {
  position: relative;
  /* Add this */
}


.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}
</style>
