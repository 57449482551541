<template>
    <div class="main">
        <div class="body-content">
            <h1 class="title">Change Password</h1>
            <div class="form-group">
                <label for="oldpassword">Old Password *</label>
                <input v-model="oldpassword" id="oldpassword" type="password" placeholder="Password" required
                    class="input-field" />
            </div>
            <div class="form-group">
                <label for="newpassword">New Password *</label>
                <input v-model="newpassword" id="newpassword" type="password" placeholder="Password" required
                    class="input-field" />
            </div>
            <div class="form-group">
                <label for="retypepassword">Retype Password *</label>
                <input v-model="retypepassword" id="retypepassword" type="password" placeholder="Password" required
                    class="input-field" />
            </div>

            <button type="submit" class="login-button" @click="handleSubmit">Change Password</button>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
    name: 'UserLogin',
    data() {
        return {
            oldpassword: '',
            newpassword: '',
            retypepassword: '',
        };
    },
    methods: {
        async handleSubmit() {
            try {
                console.log(this.oldpassword)
                //const params = new URLSearchParams();
                //params.append('username', this.username);
                //params.append('password', this.password);
                //let data = JSON.stringify({
                //    "oldpassword": this.oldpassword,
                //    "newpassword": this.newpassword
                //});
                const token = localStorage.getItem('access_token');
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await axios.post('/change-password?oldpassword=' + this.oldpassword + '&newpassword=' + this.newpassword, config);
                console.log(response)
                if (response.data && response.data.access_token) {
                    console.log(response.data.access_token)
                    console.log(this.decodeAccessToken(response.data.access_token))
                    const decodedToken = this.decodeAccessToken(response.data.access_token);
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('user', JSON.stringify({
                        name: decodedToken.payload.name,
                        roleid: decodedToken.payload.roleid,
                        user_id: decodedToken.payload.user_id,
                        state_id: decodedToken.payload.state_id,
                        country_id: decodedToken.payload.country_id,
                    }));

                    // Notify app about login
                    window.dispatchEvent(new Event('storage'));

                    // Dispatch Vuex actions to update authentication state and fetch menu items
                    await this.$store.dispatch('login', {
                        token: response.data.access_token,
                        user: {
                            name: decodedToken.payload.name,
                            roleid: decodedToken.payload.roleid,
                            user_id: decodedToken.payload.user_id,
                            state_id: decodedToken.payload.state_id,
                            country_id: decodedToken.payload.country_id,
                        },
                    });
                    await this.$store.dispatch('applicants/fetchApplicants');

                    await this.$router.push('/dashboard');
                    this.$router.go(0);

                    // Show success notification
                    useToast().success('Login successful!');
                } else {
                    console.error('Invalid response from server');
                }
            } catch (error) {
                console.error('Login failed:', error);
                // Show error notification
                useToast().error('Login failed. Please check your credentials.');
            }
        },
        decodeAccessToken(token) {
            const parts = token.split('.');
            if (parts.length !== 3) {
                throw new Error('Invalid token format');
            }
            return {
                header: JSON.parse(atob(parts[0])),
                payload: JSON.parse(atob(parts[1])),
                signature: parts[2],
            };
        },
    },
};
</script>


<style scoped>
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');*/

.form-group {
    margin-bottom: 15px;
    width: 100%;
    padding: 5px;
    float: left;
}

label {
    margin-bottom: 15px;
    width: 100px;
}

select,
input[type="text"],
input[type="date"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.dx-textbox,
.dx-button {
    font-size: 16px;
}
</style>
