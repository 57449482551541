<template>
    <div class="main">
        <div class="transaction-list">
            <h1 class="title">Bill Generate</h1>
            <div class="row">
                <div class="container">
                    <!-- Form for filtering transactions -->
                    <div class="form-group">
                        <label for="partyname">Party *</label>
                        <v-select :clearable="false" id="partyname"
                            v-model="formData.partyname" :options="party" label="short_name" selectOnTab="True"
                            :reduce="party => party.autoid" />
                    </div>
                    <div class="form-group">
                        <label for="vesselName">Vessel *</label>
                        <v-select :clearable="false" id="vesselName" v-model="formData.vesselName" :options="vessel" label="Name"
                            :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
                    </div>
                    <div class="form-group">
                        <label for="voyageName">Voyage *</label>
                        <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage" label="Name"
                            :reduce="voyage => voyage.Voyageid" selectOnTab="True" />
                    </div>
                    <div class="form-group">
                        <label for="locationname">Location *</label>
                        <v-select :clearable="false" v-model="formData.locationname" :options="location" label="Name"
                            :reduce="location => location.locationid" append-to-body selectOnTab="True" />
                    </div>
                    <div class="form-group">
                        <label for="movementname">Movement Type *</label>
                        <v-select :clearable="false" id="movementname" v-model="formData.movementname" :options="movement" label="Name"
                            :reduce="movement => movement.Movid" selectOnTab="True" />
                    </div>
                    <div class="form-group">
                        <label for="fromdate">Report From Date *</label>
                        <input type="date" id="fromdate" v-model="formData.fromdate"
                            :max="new Date().toLocaleDateString('en-ca')" />
                    </div>
                    <div class="form-group">
                        <label for="todate">Report To Date *</label>
                        <input type="date" id="todate" v-model="formData.todate"
                            :max="new Date().toLocaleDateString('en-ca')" />
                    </div>
                    <div class="form-group">
                        <label for="reporttype">Report Type *</label>
                        <select id="reporttype" v-model="formData.reporttype">
                            <option value="0" selected>Select Report Type</option>
                            <option value="1">Survey</option>
                            <option value="2">Comparator</option>
                            <option value="3">Non-Eor</option>
                        </select>
                    </div>
                    <div class="form-group" style="width: 100%; display: flex; justify-content: space-between;">
                        <div style="width: 20%;">
                            <label for="contnumber">Container No</label>
                            <input type="text" id="contnumber" v-model="formData.contnumber"
                                placeholder="Generating Report for Specific Container(s)..." />
                        </div>
                        <div style="width: 20%; height: 20%;margin-top: 20px;margin-right: 10%;">
                            <button type="button" class="excel-button" @click="getUnbilledReports">Show Result</button>
                            <button type="button" class="cancel-button1" @click="resetForm">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLoading">Loading transactions...</div>
            <div v-else-if="error">Error loading transactions: {{ error }}</div>

            <div v-else>
                <div class="controls">
                    <div class="search-container">
                        <input v-model="searchQuery" placeholder="Search..." />
                        <i class="fas fa-search search-icon"></i>
                    </div>
                    <div class="actions">
                        <div>
                            Bill Date: <input type="date" id="rptdate" v-model="rptdate"
                                :max="new Date().toLocaleDateString('en-ca')" />

                        </div>
                        <div style="display: flex; align-items: center; margin-left: 10px;">
                            <input type="checkbox" id="merge-report" v-model="newTransaction.mergeReport" />
                            <label for="merge-report" style="margin-left: 5px;">Merge Report</label>
                            <input type="checkbox" id="round-off" v-model="newTransaction.roundOff"
                                style="margin-left: 10px;" />
                            <label for="round-off" style="margin-left: 5px;">Round Off</label>
                        </div>
                        <div style="margin-left: 10px;"></div>
                        <div>
                            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                                <button class="print-button" title="Print" @click="generateReport">
                                    <i class="fas fa-print"></i>Print
                                </button>
                            </div>
                        </div>
                        <button class="export-button" title="Export to CSV">
                            <i class="fas fa-ellipsis-v"></i>
                            <ul class="export-options">
                                <li @click="exportToCSV">Export to CSV</li>
                            </ul>
                        </button>
                    </div>
                </div>

                <div class="table-container">
                    <table class="transaction-table">
                        <thead>
                            <tr>
                                <th><input type="checkbox" @click="selectAll($event)" /></th>
                                <th><span>Report No</span></th>
                                <th><span>Date</span></th>
                                <th><span>Applicant</span></th>
                                <th><span>Account</span></th>
                                <th><span>20ft</span></th>
                                <th><span>40ft</span></th>
                                <th><span>Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction in paginatedTransactions" :key="transaction.rptno">
                                <td><input type="checkbox" v-model="checkedIds" :value="transaction.rptno" /></td>
                                <td>{{ transaction.rptno }}</td>
                                <td>{{ formatDate(transaction.rptdate) }}</td>
                                <td>{{ transaction.applicantname }}</td>
                                <td>{{ transaction.accountname }}</td>
                                <td>{{ transaction['20ft'] }}</td>
                                <td>{{ transaction['40ft'] }}</td>
                                <td>
                                    <button @click="openPDF(transaction.filename)" class="pdf-button">
                                        <i class="fas fa-file-pdf"></i> View PDF
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="bottom-controls">
                    <div class="rows-per-table">
                        <button v-for="option in [10, 20, 30]" :key="option"
                            :class="{ 'active': rowsPerPage === option }" @click="setRowsPerPage(option)">
                            {{ option }}
                        </button>
                    </div>
                    <div class="pagination">
                        <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
                            :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import * as cs from '../assets/js/commonscript.js'

export default {
    components: {
        VSelect,
    },
    data() {
        return {
            transactions: [],
            party: JSON.parse(localStorage.getItem('party')),
            vessel: JSON.parse(localStorage.getItem('vessel')),
            voyage: JSON.parse(localStorage.getItem('voyage')),
            location: JSON.parse(localStorage.getItem('locations')),
            movement: JSON.parse(localStorage.getItem('movements')),
            rptdate: new Date().toLocaleDateString('en-ca'),
            rpttype: 1,
            checkedIds: [],
            formData: {
                applicantid: 0,
                accountid: 0,
                partyname: 0,
                vesselName: 0,
                voyageName: 0,
                locationname: 0,
                movementname: 0,
                fromdate: '',
                todate: '',
                contnumber: '',
                reporttype: 0
            },
            nameError: '',
            isLoading: false,
            error: null,
            hasPermissions: false,
            searchQuery: '',
            rowsPerPage: 10,
            currentPage: 1,
            selectedTransaction: null,
            newTransaction: {
                contnumber: '',
                contsize: '',
                conttype: '',
                TypeText: '',
                construction: '',
                iso_code: '',
                isotarewt: '',
                isogrosswt: '',
                applicantname: '',
                accountname: '',
                VesselName: '',
                Colsign: '',
                VoyageName: '',
                MovementName: '',
                LocationName: 0,
                mergeReport: false,
                roundOff: false
            },
            sortDirection: {
                contnumber: 'asc',
                contsize: 'asc',
                conttype: 'asc',
                TypeText: 'asc',
                construction: 'asc',
                iso_code: 'asc',
                isotarewt: 'asc',
                isogrosswt: 'asc',
                applicantname: 'asc',
                accountname: 'asc',
                VesselName: 'asc',
                Colsign: 'asc',
                VoyageName: 'asc',
                MovementName: 'asc',
                LocationName: 'asc',
            },
            showAddTooltip: false,
        };
    },
    created() {
        this.checkPermissions();
    },
    computed: {
        resetTransaction() {
            return {
                contnumber: '',
                contsize: '',
                conttype: '',
                TypeText: '',
                construction: '',
                iso_code: '',
                isotarewt: '',
                isogrosswt: '',
                applicantname: '',
                accountname: '',
                VesselName: '',
                Colsign: '',
                VoyageName: '',
                MovementName: '',
                LocationName: '',
            };
        },
        filteredTransactions() {
            if (!this.transactions) {
                return
            }
            return this.transactions.filter(transaction => {
                return Object.values(transaction).some(value =>
                    String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            }).sort((a, b) => {
                for (const key in this.sortDirection) {
                    if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
                    if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
                }
                return 0;
            });
        },
        paginatedTransactions() {
            if (!this.transactions) {
                return
            }
            const start = (this.currentPage - 1) * this.rowsPerPage;
            const end = start + this.rowsPerPage;
            return this.filteredTransactions.slice(start, end);
        },
        totalPages() {
            if (!this.transactions) {
                return
            }
            return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
        },
    },
    methods: {
        selectAll(event) {
            this.checkedIds = event.target.checked
                ? this.paginatedTransactions.map(transaction => transaction.rptno)
                : [];
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(dateString).toLocaleDateString('en-GB', options);
        },
        async openPDF(filename) {
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'blob'
            };
            const imageRes = await axios.get('/ConvertImageToBlob?filename=' + filename, config);
            const url = URL.createObjectURL(imageRes.data);
            window.open(url, '_blank');
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
        },
        showTooltip() {
            this.showAddTooltip = true;
        },
        hideTooltip() {
            this.showAddTooltip = false;
        },
        updateNewContainerIds(value) {
            const AppAcc=cs.GetAppAccFromParty(value)
            this.formData.applicantid =AppAcc.applicantid
            this.formData.accountid =AppAcc.accountid
        },
        async checkTransactionName(name) {
            if (name.trim() === '') {
                this.nameError = '';
                return;
            }

            try {
                const transactionExists = this.transactions.some(transaction => transaction.contnumber.toLowerCase() === name.toLowerCase());
                if (transactionExists) {
                    this.nameError = 'Transaction name already exists.';
                } else {
                    this.nameError = '';
                }
            } catch (err) {
                console.error('Error checking transaction name:', err);
            }
        },
        async getUnbilledReports() {
            this.isLoading = true;
            this.updateNewContainerIds(this.formData.partyname)
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            // Ensure all necessary parameters are set
            const reportData = {
                applicant: this.formData.applicantid || 0,
                account: this.formData.accountid || 0,
                vessel: this.formData.vesselName || 0,
                voyage: this.formData.voyageName || 0,
                movement: this.formData.movementname || 0,
                location: this.formData.locationname || 0,
                contnumber: this.formData.contnumber || '',
                reporttype: this.formData.reporttype || 0,
                fromdate: this.formData.fromdate || '', // Ensure fromdate is included
                todate: this.formData.todate || '' // Ensure todate is included
            };

            // Ensure location is set to 0 if it's an empty string
            if (reportData.location === '') {
                reportData.location = 0;
            }

            try {
                const response = await axios.get('/get_unbilled_reports', {
                    params: reportData,
                    headers: config.headers // Ensure headers are passed correctly
                });
                //console.log(response.data[2]);
                this.transactions = response.data[2];
            } catch (error) {
                console.error('Error fetching unbilled reports:', error);
            } finally {
                this.isLoading = false;
            }
        },
        checkPermissions() {
            const user = JSON.parse(localStorage.getItem('user'));
            this.hasPermissions = user && user.role === 'admin';
        },
        editTransaction(transaction) {
            this.selectedTransaction = transaction;
            this.newTransaction = { ...transaction };
        },
        cancelEdit() {
            this.selectedTransaction = null;
            this.newTransaction = { ...this.resetTransaction };
        },
        async generateReport() {
            const rptdate = this.rptdate;
            const rpttype = this.rpttype;
            const checkedIds = this.checkedIds;

            if (!checkedIds) {
                alert('Please select an Inspection');
                return;
            } else if (!rptdate) {
                alert('Please insert a report date');
                return;
            } else if (!rpttype) {
                alert('Please select a Report Type');
                return;
            }

            try {
                const token = localStorage.getItem('access_token');
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                // Construct the URL with query parameters
                const url = `/generate_bill?reportid=${checkedIds}&billdate=${rptdate}&discount=0&chkround=${this.newTransaction.roundOff}`;

                // Send the GET request
                const response = await axios.get(url, config);

                // Log the successful response
                //console.log('File generated successfully:', response.data);
                if (response.data[0] == 0) {
                    useToast().error(response.data[1]);
                } else {
                    useToast().success('Created Succesful');
                }
                // Assuming the response contains the file path
                //const filePath = response.data.filePath || "/Test%20Reportc912bc4c-6c43-4c3c-b142-2dd2d3294396.pdf";
                //this.fetchByteArray(filePath);
                //this.transactions = [];
                // Optionally open the generated file (commented out for safety)
                // window.open(`D:/cits_030919/${response.data.filePath}`, "_blank");
            } catch (error) {
                console.error('Error generating report:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchByteArray(filePath) {
            // to see the "loading..." effect, I intentionally add a "setTimeout" code. that loads the pdf after "3s". You can remove it in your real app.
            await new Promise(resolve => setTimeout(resolve, 3000));
            fetch(filePath)
                .then(response => {
                    //console.log(response);
                    // for this case "response.url" is what we need, but if you fetch data for example from a database ... you may need "response.json()" or other codes;
                    this.srcData = response.url;
                    this.byteArrayPDF = true;
                })
        },
        exportToCSV() {
            // Define the header columns
            const headers = [
                "reportid", "typeid", "containerId", "contnumber", "contsize", "conttype", "TypeText",
                "construction", "iso_code", "isotarewt", "isogrosswt", "applicant", "applicantname",
                "account", "accountname", "vesselld", "VesselName", "Colsign", "voyage", "VoyageName",
                "movementId", "MovementName", "inspectionplace", "LocationName", "seal", "slot", "inspdate",
                "insptime", "tolocation", "tolocationname", "loadport", "pol", "destnport", "pod", "transport",
                "fpd", "shifts", "conditionid", "condition", "c_agentid", "note", "transporter", "tranmode",
                "cargowt", "hazstat", "joborderno", "bookingrelno", "unno", "temp", "stowloc",
                "transporterNM", "SBno", "SBdate", "isrpt", "rptno", "rptdate", "isbilled", "billno",
                "billdate", "Rate", "isDownloaded", "isCodecoDownloaded", "is_ovi_download", "filename",
                "FileDateTime", "fileDatetimeStr", "isApp", "companyId", "created_by", "CreateDate",
                "ModifiedBy", "ModiDateTime"
            ];

            // Create CSV content
            const csvContent = [
                headers.join(","), // Join header columns with commas
                ...this.transactions.map(transaction =>
                    headers.map(header => JSON.stringify(transaction[header] || '')).join(",") // Map each transaction to the header columns
                )
            ].join("\n");

            // Encode CSV content
            const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "transactions.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up by removing the link element
        },
        setRowsPerPage(option) {
            this.rowsPerPage = option;
        },
        goToPage(page) {
            this.currentPage = page;
        },
        sortByColumn(column) {
            this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
        },
    },
};
</script>

<style scoped>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}

.container {
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
    width: 22%;
    padding: 5px;
    float: left;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input::placeholder {
    color: #888;
}

.excel-button,
.generate-button,
.cancel-button1 {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.excel-button {
    background-color: #17a2b8;
}

.cancel-button1 {
    background-color: #dc3545;
}

button:hover {
    opacity: 0.9;
}

.date-range {
    display: flex;
    justify-content: space-between;
}

.date-range label {
    flex: 1;
    margin: 0 10px;
}

.date-range input {
    flex: 2;
}





.main {
    background-color: #f0f2f5;
    font-family: 'Roboto', sans-serif;
}



.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: visible;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the button */
    left: 50%;
    margin-left: -60px;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
    opacity: 1;
}

.transaction-list {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: #333;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.search-container {
    position: relative;
}

.search-container input {
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.error-message {
    color: #d9534f;
    /* Bootstrap's danger color */
    font-size: 12px;
    margin-bottom: 4px;
    /* Space between the error message and input field */
    padding: 4px;
    border: 1px solid #d9534f;
    border-radius: 4px;
    background-color: #f9d6d5;
    /* Light red background */
    display: inline-block;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #666;
    cursor: pointer;
}

.actions {
    display: flex;
    align-items: center;
}

.print-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.print-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    margin-left: 10px;
}

.export-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-options {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: left;
    top: 40px;
    border-radius: 10px;
}

.export-button:hover .export-options {
    display: block;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.export-options li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}

.export-options li:hover {
    background-color: #ddd;
}

.table-container {
    max-height: 100%;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.transaction-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.transaction-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.transaction-table th .filter-icon {
    cursor: pointer;
    margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
    color: green;
}

.cancel-button {
    color: red;
}

.new-row input {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.pagination button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333;
    font-size: 15px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}
</style>