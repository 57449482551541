<template>
  <div>
    <h2 class="name">Party Rate</h2>

    <!-- Filter and Submit Section -->
    <div class="filter-section">


      <!-- Applicant Name Combo Box -->
      <div>
        <label for="applicant">Applicant Name:</label>
        <v-select :clearable="false" v-model="selectedApplicant" :options="applicants" label="applicantname"
          :reduce="applicant => applicant.applicantid" placeholder="Select Applicant" class="filter-select" selectOnTab="True"
          append-to-body />
      </div>
      <!-- Account Name Combo Box -->
      <div>
        <label for="account">Account Name:</label>
        <v-select :clearable="false" v-model="selectedAccount" :options="accounts" label="accountname"
          :reduce="account => account.accountid" placeholder="Select Account" class="filter-select" selectOnTab="True"
          append-to-body />
      </div>
      <!-- Submit Button -->
      <div>
        <button @click="getPartyRates" class="submit-button">Submit</button>
      </div>
    </div>

    <!-- Create Button -->
    <div v-if="showCreateButton">
      <button @click="createRow" class="create-button">Create New Row</button>
    </div>

    <!-- Display results or error message -->
    <div v-if="partyRates">
      <h3 class="name">Party Rates</h3>
      <table>
        <thead>
          <tr>
            <th>Movement Name</th>
            <th>Size</th>
            <th>Rate</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <!-- New Row for Creation -->
          <tr v-if="creatingRow">
            <td>
              <v-select :clearable="false" v-model="newRate.movementid" :options="movements" label="Name"
                :reduce="movement => movement.Movid" placeholder="Select Movement" class="movement-select" selectOnTab="True"
                ref="movementSelect" />
            </td>
            <td>
              <input :clearable="false" v-model.number="newRate.size" type="number" step="1" class="editable-input"
                placeholder="Size" @input="validateInteger($event, 'size')" />
            </td>
            <td>
              <input v-model.number="newRate.rate" type="number" step="1" class="editable-input" placeholder="Rate"
                @input="validateInteger($event, 'rate')" />
            </td>
            <td>
              <button @click="saveNewRow" class="save-button">
                <i class="fas fa-save"></i>
              </button>
              <button @click="cancelNewRow" class="cancel-button">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>

          <!-- Existing Rows -->
          <tr v-for="(rate, index) in partyRates[2]" :key="index">
            <td>
              <div v-if="!editableRows[rate.id]">
                {{ getMovementName(rate.movementid) }}
              </div>
              <v-select :clearable="false" v-if="editableRows[rate.id]" v-model="rate.movementid" :options="movements" selectOnTab="True"
                label="Name" :reduce="movement => movement.Movid" placeholder="Select Movement" class="movement-select"
                ref="movementSelect" />
            </td>
            <td>
              <div v-if="!editableRows[rate.id]">
                {{ rate.size }}
              </div>
              <input v-if="editableRows[rate.id]" v-model.number="rate.size" type="number" step="1"
                class="editable-input" @input="validateInteger($event, 'size')" />
            </td>
            <td>
              <div v-if="!editableRows[rate.id]">
                {{ rate.rate }}
              </div>
              <input v-if="editableRows[rate.id]" v-model.number="rate.rate" type="number" step="1"
                class="editable-input" @input="validateInteger($event, 'rate')" />
            </td>
            <td>
              <div v-if="!editableRows[rate.id]">
                <button @click="editRow(rate)" class="edit-button">
                  <i class="fas fa-edit"></i>
                </button>
                <button @click="deleteRow(rate)" class="delete-button">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <div v-if="editableRows[rate.id]">
                <button @click="saveRow(rate)" class="save-button">
                  <i class="fas fa-save"></i>
                </button>
                <button @click="cancelEdit(rate)" class="cancel-button">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="errorMessage">
      <p style="color: red">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      accounts: [],
      applicants: [],
      selectedAccount: null,
      selectedApplicant: null,
      partyRates: null,
      errorMessage: '',
      movements: JSON.parse(localStorage.getItem('movements') || '[]'),
      editableRows: {},
      newRate: {
        movementid: null,
        size: null,
        rate: null,
      },
      creatingRow: false,
      showCreateButton: false
    };
  },
  created() {
    // Load accounts and applicants from local storage
    this.accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
    this.applicants = JSON.parse(localStorage.getItem('applicants') || '[]');
  },
  
  methods: {
    getAuthToken() {
      // Retrieve the token from local storage
      return localStorage.getItem('access_token');
    },
    createPartyRate() {
    if (this.newRate.movementid && this.newRate.size && this.newRate.rate) {
      const authToken = this.getAuthToken();

      // Prepare data according to the schema
      const data = {
        id: 0, // Assuming 0 means creating a new rate
        applicantid: this.selectedApplicant,
        accountid: this.selectedAccount,
        size: this.newRate.size,
        movementid: this.newRate.movementid,
        rate: this.newRate.rate,
        companyid: 0, // Set this to the appropriate value
        created_by: 0, // Set this to the appropriate value
        create_date: new Date().toISOString(),
        last_modified_by: 0, // Set this to the appropriate value
        last_modified_date: new Date().toISOString(),
      };

      axios
        .post('/create_partyrate', data, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          // Handle success
          console.log('Party rate created:', response.data);
          this.getPartyRates(); // Refresh the list of party rates
          this.cancelNewRow(); // Hide the creation form
        })
        .catch((error) => {
          // Handle error
          this.errorMessage = 'Failed to create party rate';
          console.error(error);
        });
    } else {
      this.errorMessage = 'Please fill in all fields';
    }
  },
    getPartyRates() {
      if (this.selectedAccount && this.selectedApplicant) {
        const authToken = this.getAuthToken();

        axios
          .get('/get_partyrates', {
            params: {
              applicantid: this.selectedApplicant,
              accountid: this.selectedAccount,
            },
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${authToken}`, // Include the token in the headers
            },
          })
          .then((response) => {
            this.partyRates = response.data; // Display data
            this.errorMessage = '';
            this.showCreateButton = true;  // Clear error message
          })
          .catch((error) => {
            this.errorMessage = 'Failed to fetch party rates';
            console.error(error);
          });
      } else {
        this.errorMessage = 'Please select both account and applicant';
      }
    },
    getMovementName(movementId) {
      const movement = this.movements.find((m) => m.Movid === movementId);
      return movement ? movement.Name : '';
    },
    editRow(rate) {
      if (!this.creatingRow && !Object.values(this.editableRows).includes(true)) {
        this.creatingRow = false; // Disable create mode
        this.editableRows = { [rate.id]: true }; // Set this row to editable
        this.$nextTick(() => {
          this.$refs.movementSelect?.$el?.focus();
        });
      }
    },
    createRow() {
      if (!this.creatingRow && !Object.values(this.editableRows).includes(true)) {
        this.creatingRow = true;
        this.editableRows = {}; // Disable edit mode for all rows
        this.$nextTick(() => {
          this.$refs.movementSelect?.$el?.focus();
        });
      }
    },
    saveRow(rate) {
      //console.log(`Save changes for row with ID ${rate.id}`);
      this.editableRows = { [rate.id]: false };
    },
    cancelEdit(rate) {
      this.editableRows = { [rate.id]: false };
    },
    deleteRow(rate) {
      if (confirm('Are you sure you want to delete this row?')) {
        console.log(`Delete row with ID ${rate.id}`);
      }
    },
    saveNewRow() {
      //console.log('Save new row:', this.newRate);
      this.createPartyRate();
    },
    cancelNewRow() {
      this.creatingRow = false;
      this.newRate = {
        movementid: null,
        size: null,
        rate: null,
      };
    },
    validateInteger(event, field) {
      const value = event.target.value;
      event.target.value = value.replace(/\D/g, ''); // Only digits
      if (field === 'size') {
        this.newRate.size = Number(event.target.value);
      } else if (field === 'rate') {
        this.newRate.rate = Number(event.target.value);
      }
    },
  },
};
</script>






<style scoped>
.name {
  margin-left: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-left: 20px;
  border: 1px solid #ddd;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f0f0f0;
}

.submit-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.submit-button:hover {
  background-color: #3e8e41;
}

.create-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 20px;
}

.create-button:hover {
  background-color: #0056b3;
}

.edit-button, .delete-button, .save-button, .cancel-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
}

.edit-button {
  color: #337ab7;
}

.edit-button:hover {
  color: #23527c;
}

.delete-button {
  color: #d9534f;
}

.delete-button:hover {
  color: #c9302c;
}

.save-button {
  color: #5bc0de;
}

.save-button:hover {
  color: #31b0d5;
}

.cancel-button {
  color: #f0ad4e;
}

.cancel-button:hover {
  color: #ec971f;
}

.filter-section {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.filter-select {
  width: 150px;
}

.movement-select {
  width: 200px;
}

.editable-input {
  width: 100px;
}

.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
</style>
