export function myFoo(a, b) {
    return a + b;
}

export function GetAppAccFromParty(value) {
    const party = JSON.parse(localStorage.getItem('party'));
    const selectedParty = party.find(party => party.autoid === value);
    var AppAcc = { 'applicantid': 0, 'accountid': 0 }
    if (selectedParty.autoid !== 0) {
        if (selectedParty) {
            AppAcc = { 'applicantid': selectedParty.applicantId, 'accountid': selectedParty.accountId }
        } else {
            AppAcc = { 'applicantid': selectedParty.applicantId, 'accountid': selectedParty.accountId }
        }
    }
    return AppAcc;
}