<template>
  <div class="main">
    <div class="body-content">
      <h1 class="title">View Inspection</h1>
      <div class="row">
        <div class="container">
          <!-- Form for filtering transactions -->
          <div class="form-group">
            <label for="partyname">Party *</label>
            <v-select :clearable="false" placeholder="Select Party" id="partyname" v-model="formData.partyname"
              :options="party" label="short_name" :reduce="party => party.autoid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="vesselName">Vessel *</label>
            <v-select :clearable="false" id="vesselName" placeholder="Select Vessel" v-model="formData.vesselName"
              :options="vessel" label="Name" :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="voyageName">Voyage *</label>
            <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage" label="Name"
              :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="locationname">Location *</label>
            <v-select :clearable="false" v-model="formData.locationname" :options="location" label="Name"
              :reduce="location => location.locationid" placeholder="Select Location" selectOnTab="True" append-to-body />
          </div>
          <div class="form-group">
            <label for="movementname">Movement Type *</label>
            <v-select :clearable="false" v-model="formData.movementname" :options="movement" label="Name"
              :reduce="movement => movement.Movid" placeholder="Select Movement" selectOnTab="True" append-to-body />
          </div>
          <div class="form-group">
            <label for="fromdate">From Date </label>
            <input type="date" id="fromdate" v-model="formData.fromdate"
              :max="new Date().toLocaleDateString('en-ca')" />
          </div>
          <div class="form-group">
            <label for="todate">To Date </label>
            <input type="date" id="todate" v-model="formData.todate" :max="new Date().toLocaleDateString('en-ca')" />
            <!--<DatePicker v-model="formData.todate" :manualInput="true" dateFormat="dd/mm/yy" />-->
          </div>
          <div class="form-group">
            <label for="contnumber">Container No</label>
            <input type="text" id="contnumber" v-model="formData.contnumber"
              placeholder="Generating Report for Specific Container(s)..." />
          </div>
          <div class="form-group">
            <button type="button" class="excel-button" @click="fetchTransactions">Show Result</button>
            <button type="button" class="cancel-button1">Cancel</button>
          </div>
        </div>
      </div>
      <div v-if="isLoading">Loading transactions...</div>
      <div v-else-if="error">Error loading transactions: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
        </div>

        <div class="table-container">
          <table class="transaction-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Container Number</span>
                </th>
                <th>
                  <span>Container Size</span>
                </th>
                <th>
                  <span>Container Type</span>
                </th>
                <th>
                  <span>ISO Code</span>
                </th>
                <th>
                  <span>Applicant Name</span>
                </th>
                <th>
                  <span>Account Name</span>
                </th>
                <th>
                  <span>Vessel Name</span>
                </th>
                <th>
                  <span>Voyage Name</span>
                </th>
                <th>
                  <span>Movement Name</span>
                </th>
                <th>
                  <span>Inspection Place</span>
                </th>
                <th>
                  <span>Image</span>
                </th>
                <th>Actions</th>
                <th>
                  <span>Damages</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction) in paginatedTransactions" :key="transaction.reportid">
                <td>{{ transaction.contnumber }}</td>
                <td>{{ transaction.contsize }}</td>
                <td>{{ transaction.TypeText }}</td>
                <td>{{ transaction.iso_code }}</td>
                <td>{{ transaction.applicantname }}</td>
                <td>{{ transaction.accountname }}</td>
                <td>{{ transaction.VesselName }}</td>
                <td>{{ transaction.VoyageName }}</td>
                <td>{{ transaction.MovementName }}</td>
                <td>{{ transaction.LocationName }}</td>
                <td>
                  <i v-if="transaction.hasFiles == 1" class="fas fa-image image-icon"
                    @click="showOverlay(transaction.reportid)" title="View Image"></i>
                </td>
                <td>
                  <button @click="openEditModal(transaction)" class="edit-button">
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
                <td>
                  <button v-if="transaction.conditionid == 1" @click="DamageList(transaction.reportid)"
                    class="edit-button">
                    Damages
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
              :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Inline Edit Transaction Overlay -->
    <!-- Inline Edit Transaction Overlay -->
    <div v-if="isModalVisible" class="edit-overlay">
      <div class="edit-overlay-content">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h2>Container Inspection Report</h2>
        <form @submit.prevent="saveTransaction(newTransaction)">

          <!-- Row 1 -->
          <div class="form-row">
            <div class="form-group">
              <label for="contnumber">Container No:</label>
              <input type="text" id="contnumber" v-model="newTransaction.contnumber" required />
            </div>
            <div class="form-group">
              <label for="manufacture">Year of Mfg:</label>
              <input type="text" id="manufacture" v-model="newTransaction.manufacture" />
            </div>
            <div class="form-group">
              <label for="tareWeight">Tare Weight:</label>
              <input type="number" id="tareWeight" v-model="newTransaction.isotarewt" required />
            </div>
            <div class="form-group">
              <label for="maxGrossWeight">CSC Max Gross Wt:</label>
              <input type="number" id="maxGrossWeight" v-model="newTransaction.isogrosswt" required />
            </div>
          </div>

          <!-- Row 2 -->
          <div class="form-row">
            <div class="form-group">
              <label for="cargowt">Cargo Wt:</label>
              <input type="number" id="cargowt" v-model="newTransaction.cargowt" required />
            </div>
            <div class="form-group">
              <label for="vesselName">Vessel Name:</label>
              <v-select :clearable="false" v-model="newTransaction.vesselld" :options="vessel" label="Name"
                :reduce="vessel => vessel.Vesselid" placeholder="Select Vessel" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="voyageNo">Voyage Name:</label>
              <v-select :clearable="false" v-model="newTransaction.voyage" :options="voyage" label="Name"
                :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="c_agentid">Clearing Agent:</label>
              <v-select :clearable="false" :options="['Agent A', 'Agent B']" v-model="newTransaction.c_agentid"
                placeholder="Select Clearing Agent"  selectOnTab="True"/>
            </div>
          </div>

          <!-- Row 3 -->
          <div class="form-row">
            <div class="form-group">
              <label for="applicantnm">Applicant Name:</label>
              <v-select :clearable="false" v-model="newTransaction.applicant" :options="applicants" selectOnTab="True"
                label="applicantname" :reduce="applicants => applicants.applicantid" placeholder="Select Applicant" />
            </div>
            <div class="form-group">
              <label for="accountnm">A/C:</label>
              <v-select :clearable="false" v-model="newTransaction.account" :options="accounts" label="accountname"
                :reduce="accounts => accounts.acccountid" placeholder="Select Account" selectOnTab="True" />

              <!--<v-select :clearable="false" v-model="newTransaction.account" :options="accounts" selectOnTab="True"
               label="accountname" :reduce="accounts => accounts.acccountid" placeholder="Select Account" />-->
            </div>
            <div class="form-group">
              <label for="typeid">Report Type:</label>
              <select v-model="newTransaction.typeid" placeholder="Select Report Type">
                <option value="2">Non-EOR</option>
                <option value="1">EOR</option>
              </select>
            </div>
            <div class="form-group">
              <label for="seal">Seal No:</label>
              <input type="text" id="seal" v-model="newTransaction.seal" />
            </div>
          </div>

          <!-- Row 4 -->
          <div class="form-row">
            <div class="form-group">
              <label for="inspectionplace">Inspection Place:</label>
              <v-select :clearable="false" v-model="newTransaction.inspectionplace" :options="location" label="Name"
                :reduce="location => location.locationid" placeholder="Select a Location Name" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="inspectionDate">Inspection Date:</label>
              <input type="date" id="inspectionDate" v-model="newTransaction.inspdate" required />
            </div>
            <div class="form-group">
              <label for="insptime">Time:</label>
              <input type="text" id="insptime" v-model="newTransaction.insptime" required />
            </div>
            <div class="form-group">
              <label for="tolocation">To Location:</label>
              <v-select :clearable="false" :options="cfs_location" v-model="newTransaction.tolocation" label="Name"
                :reduce="location => location.locationid" placeholder="Select To Location" selectOnTab="True" />
            </div>
          </div>

          <!-- Row 5 -->
          <div class="form-row">
            <div class="form-group">
              <label for="loadport">POL:</label>
              <v-select :clearable="false" v-model="newTransaction.loadport" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="destnport">POD:</label>
              <v-select :clearable="false" v-model="newTransaction.destnport" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="transport">FPD:</label>
              <v-select :clearable="false" v-model="newTransaction.transport" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" selectOnTab="True" />
            </div>
            <div class="form-group">
              <label for="transporter">Transporter Code:</label>
              <select v-model="newTransaction.transporter">
                <option value="">Select Transporter</option>
                <option value="ASL001">ALLIED ICD(DURGAPUR)</option>
                <option value="CONKOL">CONCOR KOLKATA</option>
                <option value="HCP001">HIND CARRIER</option>
                <option value="HIN001">Hind Terminals Pvt. Ltd.</option>
                <option value="IWL001">Inland world Logistics</option>
                <option value="JSL001">JINDAL STAINLESS LTD.</option>
                <option value="MMM001">MULTI TRANS MOVER</option>
                <option value="RKE001">R. K. ENTERPRIZE</option>
                <option value="RAD001">Radhika logistics</option>
                <option value="TPR001">T.P.R.C.</option>
                <option value="VED001">VED001</option>
              </select>
            </div>
          </div>

          <!-- Row 6 -->
          <div class="form-row">
            <div class="form-group">
              <label for="tranmode">Transport Mode:</label>
              <select v-model="newTransaction.tranmode">
                <option value=''>Select Transmode</option>
                <option value="R">ROAD</option>
                <option value="T">TRAIN</option>
              </select>
            </div>
            <div class="form-group">
              <label for="bookingrelno">Booking Ref. No:</label>
              <input type="text" id="bookingrelno" v-model="newTransaction.bookingrelno" />
            </div>
            <div class="form-group">
              <label for="joborderno">Job Order No:</label>
              <input type="text" id="joborderno" v-model="newTransaction.joborderno" />
            </div>
            <div class="form-group">
              <label for="hazstat">Haz Status:</label>
              <select v-model="newTransaction.hazstat">
                <option value="N">No</option>
                <option value="Y">Yes</option>
              </select>
            </div>
          </div>

          <!-- Row 7 -->
          <div class="form-row">
            <div class="form-group">
              <label for="note">Note:</label>
              <textarea id="note" v-model="newTransaction.note"></textarea>
            </div>
          </div>

          <!-- Radio Buttons for Sound/Damage -->
          <div class="form-row">
            <div class="form-group">
              <div class="radio-group">
                <label class="condition">Condition:</label>
                <span>
                  <input type="radio" id="sound" value="SOUND" v-model="newTransaction.condition" />
                  <label for="sound">Sound</label>
                </span>
                <span>
                  <input type="radio" id="damage" value="DAMAGE" v-model="newTransaction.condition" />
                  <label for="damage">Damage</label>
                </span>
              </div>
            </div>


          </div>

          <!-- Save and Cancel buttons -->
          <div class="form-buttons">
            <button type="submit" class="save-button">Save</button>
            <button type="button" class="cancel-button" @click="closeModal">Cancel</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="overlayVisible" class="overlay">
      <div class="overlay-content">
        <button class="close-button" @click="closeOverlay">✖</button>
        <img :src="currentImage" alt="Overlay Image" />
        <!-- Display nav buttons only if more than 1 image -->
        <button class="nav-button left" v-if="currentImages.length > 1" @click="prevImage"
          :disabled="currentIndex === 0">❮</button>
        <button class="nav-button right" v-if="currentImages.length > 1" @click="nextImage"
          :disabled="currentIndex === currentImages.length - 1">❯</button>
      </div>
    </div>
    <div v-if="isDamageModalVisible" class="edit-overlay">
      <div class="edit-overlay-content">
        <span class="close-btn" @click="closeDamageModal">&times;</span>
        <div class="controls">
          <h1 class="title">Damage List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddNewRow" title="Add New Damage" :disabled="selecteddmg">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Damage</div>
              </button>
            </div>
          </div>
        </div>
        <div class="table-container">
          <input type="text" id="reportid" value="0" v-model="newDmg.Reportid" class="overlay-form-input" />
          <div v-if="isAddingNew" style="padding: 10px;border: 1px solid #CCC;margin-left: 20px;width: 80%;">
            <div class="overlay-form-group">
              <label for="location">Location:</label>
              <select id="location" v-model="newDmg.Location" class="overlay-form-select">
                <option value="0" selected>Select Location</option>
                <option value="1">EXT</option>
                <option value="2">INT</option>
              </select>
              <label for="position">Position:</label>
              <v-select :clearable="false" id="position" v-model="newDmg.Position" :options="positions" label="name"
                placeholder="Select Position" :reduce="pos => pos.id" class="overlay-form-select" selectOnTab="True" />
            </div>
            <div class="overlay-form-group">
              <label for="damageFrom">Damage From:</label>
              <v-select :clearable="false" style="width: 30%;" v-model="newDmg.FromSec1" :options="FromSec1"
                :reduce="sec => sec.id" label="name" class="overlay-form-input" selectOnTab="True" />

              <label for="damageFrom">&</label>
              <v-select :clearable="false" style="width: 30%;" v-model="newDmg.Fromsec2" :options="FromSec2"
                :reduce="sec => sec.id" label="name" class="overlay-form-input" selectOnTab="True" />

            </div>

            <div class="overlay-form-group">
              <label for="damageTo">Damage To:</label>
              <v-select :clearable="false" id="damageTo" style="width: 30%;" v-model="newDmg.ToSec1" :options="ToSec1"
                :reduce="sec => sec.id" label="name" class="overlay-form-input" selectOnTab="True" />

              <label for="damageTo">&:</label>
              <v-select :clearable="false" id="damageTo" style="width: 30%;" v-model="newDmg.ToSec2" :options="ToSec2"
                :reduce="sec => sec.id" label="name" class="overlay-form-input" selectOnTab="True" />

            </div>

            <div class="overlay-form-group">
              <label for="component">Component:</label>
              <v-select :clearable="false" style="width: 150px;" id="component" v-model="newDmg.Componentid"
                :options="components" label="description" :reduce="component => component.id" class="overlay-form-input"
                placeholder="Select Component" selectOnTab="True" />

              <label for="damageType">Damage Type:</label>
              <v-select :clearable="false" style="width: 150px;" id="damageType" v-model="newDmg.DamageType"
                :options="damageTypes" label="description" :reduce="damageType => damageType.id"
                class="overlay-form-input" placeholder="Select Damage Type " selectOnTab="True" />

              <label for="repairType">Repair Type:</label>
              <v-select :clearable="false" style="width: 150px;" id="repairType" v-model="newDmg.Repairid"
                :options="repairTypes" label="description" :reduce="repairType => repairType.id"
                class="overlay-form-input" placeholder="Select Repair Type" selectOnTab="True" />
            </div>
            <div class="overlay-form-group">
              <label for="length">Length:</label>
              <input style="width: 60px;" type="number" value="0" id="length" v-model="newDmg.Length"
                class="overlay-form-input" />
              <label for="breadth">Breadth:</label>
              <input style="width: 60px;" type="number" id="breadth" v-model="newDmg.Breadth"
                class="overlay-form-input" />
              <label for="area">Area:</label>
              <input style="width: 60px;" type="number" id="area" value="0" v-model="newDmg.area"
                class="overlay-form-input" />
              <label for="quantity">Quantity:</label>
              <input style="width: 60px;" type="number" id="quantity" value="0" v-model="newDmg.Quantity"
                class="overlay-form-input" />
            </div>
            <div>
              <textarea v-model="newDmg.note" placeholder="Note" rows="3"></textarea>
            </div>

            <button @click="saveDamage" class="save-button"><i class="fas fa-save"></i></button>
            <button @click="cancelNewDamage" class="cancel-button"><i class="fas fa-times"></i></button>
          </div>
          <table class="table-design">
            <thead>
              <tr>
                <th>
                  <span>Location</span>
                </th>
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>FromSec1</span>
                </th>
                <th>
                  <span>Fromsec2</span>
                </th>
                <th>
                  <span>ToSec1</span>
                </th>
                <th>
                  <span>ToSec2</span>
                </th>
                <th>
                  <span>Componentid</span>
                </th>
                <th>
                  <span>DamageType</span>
                </th>
                <th>
                  <span>Repairid</span>
                </th>
                <th>
                  <span>Length</span>
                </th>
                <th>
                  <span>Breadth</span>
                </th>
                <th>
                  <span>area</span>
                </th>
                <th>
                  <span>Quantity</span>
                </th>
                <th>
                  <span>Note</span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-row" v-for="(dmg) in damages" :key="dmg.autoid"
                :class="{ 'editing': dmg === selecteddmg }">
                <td v-if="dmg === selecteddmg">
                  <select id="location" v-model="newDmg.Location" class="overlay-form-select">
                    <option value="0" selected>Select Position</option>
                    <option value="1">EXT</option>
                    <option value="2">INT</option>
                  </select>
                </td>
                <td v-else>{{ dmg.LocationTxt }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="position" v-model="newDmg.Position" :options="positions" label="name"
                    placeholder="Select Position" :reduce="pos => pos.id" class="overlay-form-select" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.PositionTxt }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="damageFrom" placeholder="Select From" v-model="newDmg.FromSec1"
                    :options="FromSec1" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.sFromSec1 }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="damageFromAnd" v-model="newDmg.Fromsec2" placeholder="Select From2"
                    :options="FromSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.sFromSec2 }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="damageTo" v-model="newDmg.ToSec1" :options="ToSec1" label="name"
                    :reduce="fromsec => fromsec.id" class="overlay-form-select" placeholder="Select To" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.sToSec1 }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="damageToAnd" v-model="newDmg.ToSec2" :options="ToSec2" label="name"
                    :reduce="fromsec => fromsec.id" class="overlay-form-select" placeholder="Select To2" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.sToSec2 }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="component" v-model="newDmg.Componentid" :options="components"
                    label="description" :reduce="component => component.id" class="overlay-form-input"
                    placeholder="Select Component" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.Component }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="damageType" v-model="newDmg.DamageType" :options="damageTypes"
                    label="description" :reduce="dmgtype => dmgtype.id" class="overlay-form-input"
                    placeholder="Select Damage" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.Damage }}</td>

                <td v-if="dmg === selecteddmg">
                  <v-select :clearable="false" id="repairType" v-model="newDmg.Repairid" :options="repairTypes"
                    label="description" :reduce="repairtype => repairtype.id" class="overlay-form-input"
                    placeholder="Select Repair" selectOnTab="True" />
                </td>
                <td v-else>{{ dmg.sRepair }}</td>

                <td v-if="dmg === selecteddmg">
                  <input type="number" value="0" id="length" v-model="newDmg.Length" class="overlay-form-input" />
                </td>
                <td v-else>{{ dmg.Length }}</td>

                <td v-if="dmg === selecteddmg">
                  <input type="number" id="breadth" v-model="newDmg.Breadth" class="overlay-form-input" />
                </td>
                <td v-else>{{ dmg.Breadth }}</td>

                <td v-if="dmg === selecteddmg">
                  <input style="width: 60px;" type="number" id="area" value="0" v-model="newDmg.area"
                    class="overlay-form-input" />
                </td>
                <td v-else>{{ dmg.area }}</td>

                <td v-if="dmg === selecteddmg">
                  <input type="number" id="quantity" value="0" v-model="newDmg.Quantity" class="overlay-form-input" />
                </td>
                <td v-else>{{ dmg.Quantity }}</td>
                <td v-if="dmg === selecteddmg">
                  <textarea v-model="newDmg.note" class="overlay-form-input" value="{{dmg.note}}" row="1"
                    style="min-height: 28px;"></textarea>
                </td>
                <td v-else>{{ dmg.note }}</td>
                <td v-if="hasPermissions">
                  <button v-if="dmg === selecteddmg" @click="saveDamage" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="dmg === selecteddmg" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editDamage(dmg)" class="edit-button"><i class="fas fa-edit"></i></button>
                  <button v-if="dmg !== selecteddmg" @click="deleteDamage(dmg)" class="edit-button"
                    style="color: red;"><i class="fas fa-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Row 1 -->

        <!-- Save and Cancel buttons -->
        <div class="form-buttons">
          <button type="button" class="cancel-button" @click="closeDamageModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
//import applicants from '@/store/modules/applicants';
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
//import DatePicker from 'primevue/datepicker';

export default {
  components: {
    VSelect,
    //DatePicker,
  },
  data() {
    return {
      overlayVisible: false,
      currentImage: '',
      currentIndex: 0,
      currentImages: [],
      transactions: [],
      damages: [],
      selecteddmg: null,
      //images: [],
      party: JSON.parse(localStorage.getItem('party')) || [],
      vessel: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      location: JSON.parse(localStorage.getItem('locations')) || [],
      cfs_location: JSON.parse(localStorage.getItem('cfs_location')) || [],
      movement: JSON.parse(localStorage.getItem('movements')) || [],
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
      port: JSON.parse(localStorage.getItem('ports')) || [],
      nameError: '',
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      selectedTransaction: null,
      isDamageModalVisible: false,
      isModalVisible: false, // Control visibility of the modal
      isAddingNew: false,
      locations: [
        { id: 0, name: 'Select One' },
        { id: 1, Name: 'EXT' },
        { id: 2, Name: 'INT' },],
      positions: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Left' },
        { id: 2, name: 'Front' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Top' },
        { id: 5, name: 'Rear' },
        { id: 6, name: 'Interior' },
        { id: 7, name: 'Under Structure' },
        { id: 8, name: 'Exterior' },
      ],
      FromSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      FromSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      ToSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      ToSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],
      formData: {
        applicantid: 0,
        accountid: 0,
        partyname: 0,
        vesselName: 0,
        voyageName: 0,
        locationname: 0,
        movementname: 0,
        fromdate: '',
        todate: '',
        contnumber: '',
      },
      newTransaction: {
        reportid: 0,
        containerId: 0,
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicant: '',
        account: '',
        vesselld: '',
        voyage: '',
        movementId: 0,
        inspectionplace: 0,
        LocationName: '',
        manufacture: '',
        tareWeight: '',
        maxGrossWeight: '',
        cargowt: '',
        c_agentid: '',
        typeid: 2,
        inspdate: '',
        insptime: '',
        tolocation: '',
        seal: '',
        slot: '',
        shifts: '',
        surveyorName: '',
        loadport: '',
        destnport: '',
        transport: '',
        hazstat: '',
        transporter: '',
        tranmode: '',
        bookingrelno: '',
        joborderno: '',
        note: '',
        condition: '',
        conditionId: 0,
      },
      newDmg: {
        autoid: '',
        Reportid: '',
        Location: 0,
        Position: 0,
        FromSec1: 0,
        Fromsec2: 0,
        ToSec1: 0,
        ToSec2: 0,
        Componentid: 0,
        DamageType: 0,
        Repairid: 0,
        Length: 0,
        Breadth: 0,
        area: 0,
        Quantity: 0,
        note: ''
      },
      damageDbList: [],
      damageDbListSave: [],
    };
  },
  created() {
    this.checkPermissions();
    this.fetchData();
  },
  computed: {
    filteredTransactions() {
      return this.transactions.filter(transaction => {
        return Object.values(transaction).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredTransactions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
    },
  },
  methods: {
    fetchData() {
      const accessToken = localStorage.getItem('access_token');
      axios.get('/active_damage_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const damageTypesResponse = response.data[2];
          this.damageTypes = damageTypesResponse.map(damageType => ({
            id: damageType.autoid,
            name: damageType.shortName,
            description: damageType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
      axios.get('/active_repair_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const repairTypesResponse = response.data[2];
          this.repairTypes = repairTypesResponse.map(repairType => ({
            id: repairType.autoid,
            name: repairType.shortName,
            description: repairType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
      axios.get('/active_component_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const componentsResponse = response.data[2];
          this.components = componentsResponse.map(component => ({
            id: component.autoid,
            name: component.shortName,
            description: component.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
    },
    async DamageList(reptid) {
      try {
        this.newDmg.Reportid = reptid
        //console.log(reptid)
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get('/get_damages?reportid=' + reptid, config);
        this.damages = response.data[2];
        this.isDamageModalVisible = true
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    },
    closeDamageModal() {
      this.isDamageModalVisible = false; // Hide the modal
      this.damages = null; // Reset selected transaction
    },
    openAddNewRow() {
      if (this.selecteddmg) {
        this.cancelEdit(); // Cancel editing if a vessel is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      //this.newVessel = { ...this.resetVessel }; // Reset new vessel fields
    },
    async saveDamage() {
      const token = localStorage.getItem('access_token');
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        //console.log(config)
        const dmg = {
          autoid: this.newDmg.autoid || 0,
          reportid: this.newDmg.Reportid || 0,
          damlocationid: this.newDmg.Location || 0,
          positionid: this.newDmg.Position || 0,
          damageFrom: this.newDmg.FromSec1 || 0,
          damageFromAnd: this.newDmg.Fromsec2 || 0,
          damageTo: this.newDmg.ToSec1 || 0,
          damageToAnd: this.newDmg.ToSec2 || 0,
          component: this.newDmg.Componentid || 0,
          damageType: this.newDmg.DamageType || 0,
          repairType: this.newDmg.Repairid || 0,
          length: this.newDmg.Length || 0,
          breadth: this.newDmg.Breadth || 0,
          area: this.newDmg.area || 0,
          quantity: this.newDmg.Quantity || 0,
          note: this.newDmg.note || ''
        };
        this.damageDbList.push(dmg);
        this.damageDbListSave.push(dmg);
        const data = new FormData();
        const damagedt = JSON.stringify(this.damageDbListSave)
        //console.log(damagedt);
        data.append('damageData', damagedt)
        //console.log(dmg);
        const response = await axios.post('/saveDamage', data, config);
        if (response.data[0] === 0) {
          // Display success message using the second element of the response
          useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.DamageList(this.newDmg.Reportid)
          this.resetNewDmg();
          this.selecteddmg = null;
          this.damageDbListSave = [];
        }

      } catch (error) {
        useToast().error('Error saving Damage');
        console.log('Error saving Damage. Check console for details.', error);
      }
    },
    cancelNewDamage() {
      this.isAddingNew = false;
      this.newDmg = { ...this.resetNewDmg };
    },
    async deleteDamage(damage) {
      const token = localStorage.getItem('access_token');
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        //console.log(config)

        this.damageDbListSave.push(damage);
        const data = new FormData();
        const damagedt = JSON.stringify(this.damageDbListSave)
        //console.log(damagedt);
        data.append('damageData', damagedt)
        //console.log(damage);
        const response = await axios.post('/deleteDamage', data, config);
        if (response.data[0] === 0) {
          useToast().error(response.data[1]);
        } else {
          useToast().success('Deleted Succesful');
          this.DamageList(this.newDmg.Reportid)
          this.resetNewDmg();
          this.selecteddmg = null;
          this.damageDbListSave = [];
        }
      } catch (error) {
        useToast().error('Error Deleting Damage');
      }
    },
    editDamage(damage) {
      if (this.isAddingNew) {
        this.cancelNewDamage(); // Cancel adding if currently adding
      }
      //console.log(damage)
      this.selecteddmg = damage;
      this.newDmg = { ...damage };
      //console.log(this.newDmg);
    },
    cancelEdit() {
      this.selecteddmg = null;
      this.newDmg = { ...this.resetNewDmg };
    },
    resetNewDmg() {
      this.newDmg.autoid = '',
        this.newDmg.Location = 0,
        this.newDmg.Position = 0,
        this.newDmg.FromSec1 = 0,
        this.newDmg.Fromsec2 = 0,
        this.newDmg.ToSec1 = 0,
        this.newDmg.ToSec2 = 0,
        this.newDmg.Componentid = 0,
        this.newDmg.DamageType = 0,
        this.newDmg.Repairid = 0,
        this.newDmg.Length = 0,
        this.newDmg.Breadth = 0,
        this.newDmg.area = 0,
        this.newDmg.Quantity = 0,
        this.newDmg.note = ''

    },
    async showOverlay(reportid) {
      try {
        //console.log(reportid)
        const token = localStorage.getItem('access_token');
        const config1 = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };

        const responce = await axios.get('/multiFileResponce?reportid=' + reportid, config1);

        this.currentImages = responce.data[2];
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        };

        const imageRes = await axios.get('/ConvertImageToBlob?filename=' + this.currentImages[0]['imagepath'], config);
        const url = URL.createObjectURL(imageRes.data);
        //this.images = url
        this.overlayVisible = true;
        this.currentImage = url
        this.currentIndex = 0;
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }

      // Delay adding active class for smooth transition
      this.$nextTick(() => {
        document.querySelector('.overlay').classList.add('overlay-active');
      });
    },
    closeOverlay() {
      // Remove active class before hiding overlay
      document.querySelector('.overlay').classList.remove('overlay-active');

      // Delay hiding overlay for smooth transition
      setTimeout(() => {
        this.overlayVisible = false;
      }, 300); // Match the duration of the transition
    },
    async prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        };
        const imageRes = await axios.get('/ConvertImageToBlob?filename=' + this.currentImages[this.currentIndex]['imagepath'], config);
        const url = URL.createObjectURL(imageRes.data);
        this.currentImage = url;
      }
    },
    async nextImage() {
      if (this.currentIndex < this.currentImages.length - 1) {
        this.currentIndex++;
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        };
        const imageRes = await axios.get('/ConvertImageToBlob?filename=' + this.currentImages[this.currentIndex]['imagepath'], config);
        const url = URL.createObjectURL(imageRes.data);
        this.currentImage = url;
      }
    },
    checkPermissions() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.hasPermissions = user;
    },

    openEditModal(transaction) {
      this.selectedTransaction = transaction;
      //console.log(transaction);
      this.newTransaction = { ...transaction };
      //console.log(this.newTransaction);
      //this.newTransaction.inspdate=this.newTransaction.inspdate.toLocaleDateString('en-ca')
      //console.log(this.newTransaction);
      this.isModalVisible = true; // Show the modal
    },
    closeModal() {
      this.isModalVisible = false; // Hide the modal
      this.selectedTransaction = null; // Reset selected transaction
    },
    async saveTransaction(transaction) {
      if (this.nameError) return;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      const conditionId = this.newTransaction.condition === 'Sound' ? 2 : 1;
      transaction.conditionid = conditionId
      console.log(transaction)
      try {
        const data = new FormData();
        const x = JSON.stringify(transaction)
        data.append('transaction', x)
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        };
        const response = await axios.post(`/edit_transaction?reportid=${this.newTransaction.reportid}`, data, config);
        console.log(response)
        this.closeModal(); // Close the modal after saving
      } catch (err) {
        this.error = 'Error saving transaction';
        console.error(err);
      }
    },
    async fetchTransactions() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const response = await axios.get('/transactions', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          params: {
            applicant: this.formData.applicantid,
            account: this.formData.accountid,
            vessel: this.formData.vesselName,
            voyage: this.formData.voyageName,
            movement: this.formData.movementname,
            location: this.formData.locationname,
            fromdate: this.formData.fromdate,
            todate: this.formData.todate,
            contnumber: this.formData.contnumber,
          },
        });
        this.transactions = response.data[2];
      } catch (err) {
        this.error = 'Error fetching transactions';
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
};
</script>



<style scoped>
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

}

.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  width: 22%;
  padding: 5px;
  float: left;
}

label {
  display: block;
  margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input::placeholder {
  color: #888;
}

.excel-button,
.generate-button,
.cancel-button1 {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.excel-button {
  background-color: #17a2b8;
}

.cancel-button1 {
  background-color: #dc3545;
}

button:hover {
  opacity: 0.9;
}

.date-range {
  display: flex;
  justify-content: space-between;
}

.date-range label {
  flex: 1;
  margin: 0 10px;
}

.date-range input {
  flex: 2;
}

.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}



.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position above the button */
  left: 50%;
  margin-left: -60px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.transaction-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  color: #d9534f;
  /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px;
  /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  /* Light red background */
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.print-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.print-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  top: 40px;
  border-radius: 10px;
}

.export-button:hover .export-options {
  display: block;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 100%;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.transaction-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.transaction-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.table-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

/* Overlay container */
.edit-overlay {
  position: fixed;
  /* Ensures the overlay stays above all other content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* Enables scrolling if the content exceeds viewport height */
  z-index: 9999;
  /* Ensures it appears above all other content */
}

/* Overlay content */
.edit-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  /* Adjusted width for better fit */
  /*max-width: 1000px;*/
  /* Adjusted max-width */
  max-height: 90vh;
  /* Limits the height to prevent overflow */
  overflow-y: auto;
  /* Enables vertical scrolling within the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 50px;
  /* Adds margin from the top */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  flex: 1 1 calc(25% - 16px);
  /* Ensures the form items fit in a row */
  min-width: 200px;
  box-sizing: border-box;
  /* Include padding and border in total width */
}

/* Ensure label and input occupy full width of .form-group */
.form-group label,
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.save-button {
  background-color: #28a745;
}

.cancel-button {
  background-color: #dc3545;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button:hover {
  background-color: #c82333;
}

.radio-group {
  display: flex;
  /* Use flexbox for horizontal alignment */
  align-items: center;
  /* Vertically center radio buttons and labels */
  justify-content: flex-start;
  /* Align radio buttons to the left side */
  width: 100%;
  /* Take up full width of the parent container */
}

.radio-group span {
  margin-right: 10px;
  /* Add space between radio buttons */
}

.radio-group label {
  margin-right: 5px;
  /* Reduce space between label and radio button */
}

.condition {
  margin-left: 75%;
}

/* Image icon style */
.image-icon {
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.image-icon:hover {
  transform: scale(1.2);
}

/* Overlay container */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

/* Make overlay visible when active */
.overlay-active {
  opacity: 1;
  visibility: visible;
}

/* Overlay content with smooth scaling */
.overlay-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out;
}

/* Smooth transition when opening */
.overlay-active .overlay-content {
  transform: scale(1);
}

/* Image inside overlay */
.overlay-content img {
  max-width: 70vw;
  max-height: 70vh;
  object-fit: contain;
}

/* Close button */
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border: none;
  font-size: x-large;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.2);
}

/* Navigation buttons */
.nav-button {
  background-color: #fff;
  border: none;
  font-size: x-large;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.nav-button:hover {
  transform: scale(1.2);
}

/* Left navigation button */
.left {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

/* Right navigation button */
.right {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}

/* Overlay form group styling */
.overlay-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Add space between form groups */
  gap: 2px;
  /* Add space between fields */
}

/* Styling for labels to ensure alignment */
.overlay-form-group label {
  margin-right: 2px;
  width: 15%;
  /* Set a fixed width for labels */
}

/* Styling for input and select elements to take available space */

.overlay-form-group .overlay-form-select {
  width: 30%;
  /* adjust the width to your desired size */
  font-size: 12px;
  /* adjust the font size to your desired size */
}
</style>