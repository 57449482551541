<template>
  <div class="main">
    <div class="account-list">
      <h1 class="title">Account List</h1>
      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Add New Account</h2>
          <!-- New Row for Adding Account -->
          <div style="min-width: 600px;">
            <div>
              <div v-if="nameError" class="error-message">{{ nameError }}</div>
              <input style="width: 95%;" v-model="newAccount.accountname" placeholder="Account Name" />
            </div>
            <div><input style="width: 95%;" v-model="newAccount.account_address" placeholder="Address" /></div>
            <div class="row">
              <div class="column"><input v-model="newAccount.account_city" placeholder="City" /></div>
              <div class="column">
                <v-select :clearable="false" style="margin-top: 5px; width: 90%;" v-model="newAccount.account_stateid"
                  :options="states" :reduce="state => state.stateId" label="stateName" placeholder="State" selectOnTab="True"></v-select>
              </div>
              <div class="column">
                <input v-model="newAccount.account_pin" placeholder="Pin" />
              </div>
            </div>
            <div class="row">
              <div class="column">
                <v-select :clearable="false" style="margin-top: 5px; width: 90%;" v-model="newAccount.account_countryid"
                  :options="countries" :reduce="country => country.CountryID" label="CountryName" selectOnTab="True"
                  placeholder="Country"></v-select>
              </div>
              <div class="column">
                <input v-model="newAccount.account_pan" placeholder="PAN" />
              </div>
              <div class="column"><input v-model="newAccount.account_gst" placeholder="GST" /></div>
            </div>
            <div class="row">
              <div><input type="email" style="width: 95%;" v-model="newAccount.email" placeholder="Email" /></div>
            </div>
            <div v-if="hasPermissions">
              <button @click="saveAccount" class="save-button"><i class="fas fa-save"></i></button>
              <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading and Error Messages -->
      <div v-if="isLoading">Loading Account list...</div>
      <div v-else-if="error" class="error-message">Error loading Account list: {{ error }}</div>

      <!-- Main Content -->
      <div v-else>
        <!-- Controls for Search and Actions -->
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openOverlay" title="Add New Account">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Account</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Table for Displaying Accounts -->
        <div class="table-container">
          <table class="account-table">
            <thead>
              <tr>
                <th>
                  <span>Account Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('accountname')"></i>
                </th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Pin</th>
                <th>PAN</th>
                <th>GST</th>
                <th>EMAIL</th>

                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>

              <!-- Existing Accounts Rows -->
              <tr v-for="(account) in paginatedAccounts" :key="account.accountid" @dblclick="editAccount(account)"
                :class="{ 'editing': account === selectedAccount }">
                <td v-if="account === selectedAccount">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newAccount.accountname" placeholder="Account Name" />
                </td>
                <td v-else>{{ account.accountname }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.account_address" placeholder="Address" />
                </td>
                <td v-else>{{ account.account_address }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.account_city" placeholder="City" />
                </td>
                <td v-else>{{ account.account_city }}</td>
                <td v-if="account === selectedAccount">
                  <v-select :clearable="false" v-model="newAccount.account_stateid" :options="states" selectOnTab="True"
                    :reduce="state => state.stateId" label="stateName" placeholder="State"></v-select>
                </td>
                <td v-else>{{ account.account_state }}</td>
                <td v-if="account === selectedAccount">
                  <v-select :clearable="false" v-model="newAccount.account_countryid" :options="countries" selectOnTab="True"
                    :reduce="country => country.CountryID" label="CountryName" placeholder="Country"></v-select>
                </td>
                <td v-else>{{ account.account_country }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.account_pin" placeholder="Pin" />
                </td>
                <td v-else>{{ account.account_pin }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.account_pan" placeholder="PAN" />
                </td>
                <td v-else>{{ account.account_pan }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.account_gst" placeholder="GST" />
                </td>
                <td v-else>{{ account.account_gst }}</td>
                <td v-if="account === selectedAccount">
                  <input v-model="newAccount.email" placeholder="TAN" />
                </td>
                <td v-else>{{ account.email }}</td>
                <td v-if="hasPermissions">
                  <button v-if="account === selectedAccount" @click="saveAccount(account)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="account === selectedAccount" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editAccount(account)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination and Rows per Page Controls -->
        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import VSelect from 'vue-select'; // Ensure v-select is imported
import 'vue-select/dist/vue-select.css';

export default {
  name: 'AccountList',
  components: { VSelect },
  data() {
    return {
      accounts: [],
      companies: [],
      showExportOptions: false,
      nameError: '',
      states: [],
      countries: [],
      isLoading: false,
      error: null,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedAccount: null,
      newAccount: {
        accountid: 0,
        accountname: '',
        account_address: '',
        account_city: '',
        account_stateid: 0,
        account_state: '',
        account_countryid: 0,
        account_country: '',
        account_pin: '',
        account_pan: '',
        account_gst: '',
        account_tan: '',
        email: '',
        account_is_active: false,
      },
      sortDirection: {
        accountname: 'asc',
        account_city: 'asc',
        companyname: 'asc',
      },
      showAddTooltip: false,
      showOverlay: false,
    };
  },
  created() {
    this.fetchAccounts();
    //this.fetchCompanies();
    this.checkPermissions();
    //this.fetchStates();
    //this.fetchCountries();
    document.addEventListener('keydown', (e) => {
      //alert(e.key)
      if (e.key == "Escape") {
        this.cancelEdit()
      }
    })
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.fetchStates();
      this.fetchCountries();
    })
  },
  computed: {
    filteredAccounts() {
      return this.accounts.filter(account => {
        return Object.values(account).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    paginatedAccounts() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredAccounts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAccounts.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage; // assuming you have a currentPage data property
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        // Show all pages if they are fewer than the maxPagesToShow
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        // Always show the first page
        pages.push(1);

        // Show a range around the current page
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        // Always show the last page
        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
  },
  methods: {
    showTooltip() {
      this.showAddTooltip = true;
    },
    hideTooltip() {
      this.showAddTooltip = false;
    },
    async fetchAccounts() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/accounts/', config);
        this.accounts = response.data[2];
        localStorage.setItem('accounts', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching accounts:', error);
        this.error = 'Error fetching accounts. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCompanies() {
      const token = localStorage.getItem('access_token');
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get('/company_list', config);
        this.companies = response.data[2];
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    },
    async checkPermissions() {
      // Simulate permission check
      this.hasPermissions = true; // Set this according to your logic
    },
    async fetchCountries() {
      this.countries = JSON.parse(localStorage.getItem('countries'));
      //console.log(this.countries);
    },
    async fetchStates() {
      this.states = JSON.parse(localStorage.getItem('states'));
      //console.log(this.states);
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      //console.log(this.showExportOptions);
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    openOverlay() {
      this.isAddingNew = true;
      this.showOverlay = true;
      this.newAccount = {
        accountid: 0,
        accountname: '',
        account_address: '',
        account_city: '',
        account_state: '',
        account_country: '',
        account_pin: '',
        account_pan: '',
        account_gst: '',
        account_tan: '',
        account_is_active: false,
      };
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    async checkAccountName(name) {
      // Check if the name is not empty
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        // Use the fetched accounts to check if the name exists (case-insensitive)
        const accountExists = this.accounts.some(account => account.accountname.toLowerCase() === name.toLowerCase());
        if (accountExists) {
          this.nameError = 'Account name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking account name:', err);
      }
    },
    async saveAccount() {
      const toast = useToast();
      const token = localStorage.getItem('access_token');
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };

        const StateName = this.states.find(state => state.stateId === this.newAccount.account_stateid);
        //console.log(StateName)
        if (StateName) {
          this.newAccount.account_state = StateName.stateName
          //console.log(StateName)
        }
        const countryN = this.countries.find(country => country.CountryID === this.newAccount.account_countryid);
        //console.log(countryN)
        if (countryN) {
          this.newAccount.account_country = countryN.CountryName
          //console.log(countryN)
        }

        if (this.selectedAccount) {
          await axios.put(`/update_account/${this.newAccount.accountid}/`, this.newAccount, config);
          toast.success('Account updated successfully!');
        } else {
          await axios.post('/createaccount/', this.newAccount, config);
          toast.success('Account created successfully!');
          this.showOverlay = false;
        }

        this.fetchAccounts();
        this.isAddingNew = false;
        this.selectedAccount = null;
      } catch (error) {
        console.error('Error saving account:', error);
        toast.error('Error saving account. Check console for details.');
      }
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.showOverlay = false;
      this.newAccount = {
        accountid: 0,
        accountname: '',
        account_address: '',
        account_city: '',
        account_state: '',
        account_country: '',
        account_pin: '',
        account_pan: '',
        account_gst: '',
        account_tan: '',
        account_is_active: false,
        companyid: null, // Reset companyid
        create_date: '',
        created_by: 0,
        last_modified_date: '',
        last_modified_by: 0,
      };
      this.nameError = '';
    },
    editAccount(account) {
      this.selectedAccount = account;
      this.newAccount = { ...account };
    },
    cancelEdit() {
      this.selectedAccount = null;
      this.newAccount = {
        accountid: 0,
        accountname: '',
        account_address: '',
        account_city: '',
        account_state: '',
        account_country: '',
        account_pin: '',
        account_pan: '',
        account_gst: '',
        account_tan: '',
        account_is_active: false,
        companyid: null, // Reset companyid
        create_date: '',
        created_by: 0,
        last_modified_date: '',
        last_modified_by: 0,
      };
      this.nameError = '';
    },
    exportToCSV() {
      // Implement CSV export functionality
      //console.log('Export to CSV clicked');
    },
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    setRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
  },
  watch: {
    'newAccount.accountname': function (newName) {
      if (this.isAddingNew || (this.selectedAccount && this.newAccount.accountname !== this.selectedAccount.accountname)) {
        this.checkAccountName(newName);
      }
    },
    searchQuery() {
      // Reset to the first page when search query changes
      this.currentPage = 1;
    },
  }
};
</script>


<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position above the button */
  left: 50%;
  margin-left: -60px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.error-message {
  color: #d9534f;
  /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px;
  /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  /* Light red background */
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button,
.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.add-button:hover,
.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  top: 40px;
  border-radius: 10px;
}

.export-button:hover .export-options {
  display: block;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.account-table {
  width: 100%;
  border-collapse: collapse;
}

.account-table th,
.account-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.account-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.account-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.export-container {
  position: relative;
  /* Add this */
}


.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* Allow scrolling if content overflows */
  z-index: 1000;
  /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  /* Allow more width */
  max-height: 90%;
  /* Restrict height to avoid overflow */
  overflow-y: auto;
  /* Scroll if content overflows vertically */
  z-index: 1001;
  /* Ensure content is above overlay background */
}

.column {
  float: left;
  width: 33.33%;
}

.column2 {
  float: left;
  width: 60%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
