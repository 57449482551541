<template>
  <div>
    <h1>User Table</h1>
    <table class="user-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.phone }}</td>
          <td>
            <i 
              class="fas fa-image image-icon" 
              @click="showOverlay(item.images)" 
              title="View Image"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="overlayVisible" class="overlay">
      <div class="overlay-content">
        <button class="close-button" @click="closeOverlay">✖</button>
        <img :src="require(`@/assets/${currentImage}`)" alt="Overlay Image" />
        <!-- Display nav buttons only if more than 1 image -->
        <button 
          class="nav-button left" 
          v-if="currentImages.length > 1"
          @click="prevImage"
          :disabled="currentIndex === 0"
        >❮</button>
        <button 
          class="nav-button right" 
          v-if="currentImages.length > 1" 
          @click="nextImage"
          :disabled="currentIndex === currentImages.length - 1"
        >❯</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { name: 'John Doe', email: 'john@example.com', phone: '123-456-7890', images: ['Image1.png', 'Image2.png', 'Image3.png'] },
        { name: 'Jane Smith', email: 'jane@example.com', phone: '098-765-4321', images: ['Image4.png', 'Image5.png'] },
      ],
      overlayVisible: false,
      currentImage: '',
      currentIndex: 0,
      currentImages: [],
    }
  },
  methods: {
    showOverlay(images) {
      this.overlayVisible = true;
      this.currentImages = images;
      this.currentImage = images[0];
      this.currentIndex = 0;
      
      // Delay adding active class for smooth transition
      this.$nextTick(() => {
        document.querySelector('.overlay').classList.add('overlay-active');
      });
    },
    closeOverlay() {
      // Remove active class before hiding overlay
      document.querySelector('.overlay').classList.remove('overlay-active');
      
      // Delay hiding overlay for smooth transition
      setTimeout(() => {
        this.overlayVisible = false;
      }, 300); // Match the duration of the transition
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentImage = this.currentImages[this.currentIndex];
      }
    },
    nextImage() {
      if (this.currentIndex < this.currentImages.length - 1) {
        this.currentIndex++;
        this.currentImage = this.currentImages[this.currentIndex];
      }
    },
  },
}
</script>

<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th, .user-table td {
  padding: 12px;
  text-align: left;
}
.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.user-table th {
  background-color: #f2f2f2;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Image icon style */
.image-icon {
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.image-icon:hover {
  transform: scale(1.2);
}

/* Overlay container */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

/* Make overlay visible when active */
.overlay-active {
  opacity: 1;
  visibility: visible;
}

/* Overlay content with smooth scaling */
.overlay-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out;
}

/* Smooth transition when opening */
.overlay-active .overlay-content {
  transform: scale(1);
}

/* Image inside overlay */
.overlay-content img {
  max-width: 70vw;
  max-height: 70vh;
  object-fit: contain;
}

/* Close button */
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border: none;
  font-size: x-large;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.2);
}

/* Navigation buttons */
.nav-button {
  background-color: #fff;
  border: none;
  font-size: x-large;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.nav-button:hover {
  transform: scale(1.2);
}

/* Left navigation button */
.left {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

/* Right navigation button */
.right {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
}

</style>
