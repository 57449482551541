import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
import UserLogin from '../views/LoginView.vue';
import UserDashboard from '../views/UserDashboard.vue';
import ChangePassword from '../views/ChangePassword.vue';
import VoyageContent from '../menu_items/VoyageContent.vue';
import LocationContent from '../menu_items/LocationContent.vue';
import MovementContent from '../menu_items/MovementContent.vue';
import ContainerContent from '../menu_items/ContainerContent.vue';
import VesselContent from '../menu_items/VesselsContent.vue';
import ApplicantContent from '../menu_items/ApplicantContent.vue';
import VcnContent from '../menu_items/VcnContent.vue';
import CompanyContent from '../menu_items/CompanyContent.vue';
import AccountContent from '../menu_items/AccountContent.vue';
import UploadContent from '../menu_items/UploadContent.vue';
import TransactionPage from '../menu_items/TransactionPage.vue';
import TransactionUser from '../menu_items/TransactionUser.vue';
import TransactionManagment from '../menu_items/TransactionManagment.vue';
import PortContent from '../menu_items/PortContent.vue';
import ListReport from '../menu_items/ListReport.vue';
import InspectionReportContent from '@/menu_items/InspectionReportContent.vue';
import PartyRate from '@/menu_items/PartyRate.vue';
import BillPage from '@/menu_items/AllBillPage.vue';
import BillGenerate from '@/menu_items/BillGenerate.vue';
import FileNot from '../menu_items/FileNot.vue';
import MisReport from '@/menu_items/MisReport.vue';
import ReportList from '@/menu_items/ReportList.vue';
import DamageEntry from '@/menu_items/DamageEntry.vue';
import LinkParty from '@/menu_items/LinkParty.vue';
import DamageList from '@/menu_items/DamageList.vue';
import ComponentList from '@/menu_items/ComponentList.vue';
import RepairType from '@/menu_items/RepairType.vue';
import ImagePage from '@/menu_items/ImagePage.vue';
import ImageContent from '@/menu_items/ImageContent.vue';
import UploadInspectionContent from '@/menu_items/UploadInspectionContent.vue';
import PendingInspectionList from '@/menu_items/PendingInpectionList.vue';
import OvaReport from '@/menu_items/OvaReport.vue';
import MscEdiExcel from '@/menu_items/MscEdiExcel.vue';
import GeneralExcel from '@/menu_items/GeneralExcel.vue';



// Function to check if user is authenticated
const isAuthenticated = () => {
  const accessToken = localStorage.getItem('access_token');
  return !!accessToken; // Returns true if access_token exists
};

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/transactionmanagment',
    name: 'transactionmanagment',
    component: TransactionManagment
  },
  {
    path: '/imagepage',
    name: 'imagepage',
    component: ImagePage
  },
  {
    path: '/imageview',
    name: 'imageview',
    component: ImageContent
  },
  {
    path: '/repairtype',
    name: 'repairtype',
    component: RepairType
  },
  {
    path: '/componentlist',
    name: 'componentlist',
    component: ComponentList
  },
  {
    path: '/damagelist',
    name: 'damagelist',
    component: DamageList
  },
  {
    path: '/linkparty',
    name: 'partylink',
    component: LinkParty
  },
  {
    path: '/damageentry',
    name: 'damageentry',
    component: DamageEntry
  },
  {
    path: '/listreport',
    name: 'listreport',
    component: ReportList
  },
  {
    path: '/misreport',
    name: 'misreport',
    component: MisReport
  },
   {
    path: '/underdevelopment',
    name: 'notfound',
    component: FileNot
  },
  {
    path: '/billgenerate',
    name: 'createbill',
    component: BillGenerate
  },
  {
    path: '/bill',
    name: 'bills',
    component: BillPage
  },
  {
    path: '/partyrate',
    name: 'partyrate',
    component: PartyRate
  },
  {
    path: '/report',
    name: 'ListReport',
    component: ListReport
  },
  {
    path: '/inspectionreport',
    name: 'inspectionreport',
    component: InspectionReportContent
  },
  {
    path: '/port',
    name: 'port',
    component: PortContent
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: TransactionPage
  },
  {
    path: '/transactionuser',
    name: 'transactionuser',
    component: TransactionUser
  },
  {
    path: '/upload',
    name: 'importupload',
    component: UploadContent
  },
  {
    path: '/account',
    name: 'account',
    component: AccountContent
  },
  {
    path: '/applicant',
    name: 'applicant',
    component: ApplicantContent
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyContent
  },
  {
    path: '/vcn',
    name: 'vcn',
    component: VcnContent
  },
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/voyage',
    name: 'voyage',
    component: VoyageContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/movement',
    name: 'movement',
    component: MovementContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/container',
    name: 'container',
    component: ContainerContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/vessel',
    name: 'vessel',
    component: VesselContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/location',
    name: 'location',
    component: LocationContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: UserDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/uploadtransaction',
    name: 'uploadtransaction',
    component: UploadInspectionContent,
    meta: { requiresAuth: true }
  },
  {
    path: '/pendingtransaction',
    name: 'pendingtransaction',
    component: PendingInspectionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/ovareport',
    name: 'ovareport',
    component: OvaReport,
    meta: { requiresAuth: true }
  },
  {
    path: '/mscediexcel',
    name: 'mscediexcel',
    component: MscEdiExcel,
    meta: { requiresAuth: true }
  },
  {
    path: '/generalexcel',
    name: 'generalexcel',
    component: GeneralExcel,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard to check for access token before each route
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!isAuthenticated()) {
//       next({
//         path: '/',
//         query: { redirect: to.fullPath }
//       });
//     } else {
//       next();
//     }
//   } else {
//     next(); // always call next()!
//   }
// });

router.beforeEach((to, from, next) => {
  // If the route requires authentication and the user is not authenticated
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } 
  // If the user is authenticated and trying to access the login page, redirect to dashboard
  else if (to.path === '/' && isAuthenticated()) {
    next({ path: '/dashboard' });
  } 
  // Otherwise, proceed as usual
  else {
    next(); // always call next()!
  }
});

export default router;
