<template>
  <div class="main">
    <div class="body-content">
      <div v-if="isLoading">Loading Movement list...</div>
      <div v-else-if="error">Error loading Movement list: {{ error }}</div>
      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Movement List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Movement" :disabled="selectedMovement">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="movement-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Movement Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Name')"></i>
                </th>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Description')"></i>
                </th>
                <th>
                  <span>iBox Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('iboxcode')"></i>
                </th>
                <th>
                  <span>oVac Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ovacode')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newMovement.Name" placeholder="Movement Name" />
                </td>
                <td><input v-model="newMovement.Description" placeholder="Description" /></td>
                <td><input v-model="newMovement.iboxcode" placeholder="iBox Code" /></td>
                <td><input v-model="newMovement.ovacode" placeholder="oVac Code" /></td>
                <td v-if="hasPermissions">
                  <button @click="saveMovement" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr class="new-row" v-for="(movement) in paginatedMovements" :key="movement.Movid"
                :class="{ 'editing': movement === selectedMovement }">
                <td v-if="movement === selectedMovement">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newMovement.Name" placeholder="Movement Name" />
                </td>
                <td v-else>{{ movement.Name }}</td>
                <td v-if="movement === selectedMovement">
                  <input v-model="newMovement.Description" placeholder="Description" />
                </td>
                <td v-else>{{ movement.Description }}</td>
                <td v-if="movement === selectedMovement">
                  <input v-model="newMovement.iboxcode" placeholder="iBox Code" />
                </td>
                <td v-else>{{ movement.iboxcode }}</td>
                <td v-if="movement === selectedMovement">
                  <input v-model="newMovement.ovacode" placeholder="oVac Code" />
                </td>
                <td v-else>{{ movement.ovacode }}</td>
                <td v-if="hasPermissions">
                  <button v-if="movement === selectedMovement" @click="saveMovement(movement)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="movement === selectedMovement" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editMovement(movement)" class="edit-button"><i
                      class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      movements: [],
      isLoading: false,
      showExportOptions: false,
      error: null,
      nameError: '',
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedMovement: null,
      newMovement: {
        Movid: 0,
        Name: '',
        Description: '',
        PreviousMove: 0,
        movefor: '',
        iboxcode: '',
        ovacode: '',
        companyid: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      },
      sortDirection: {
        Name: 'asc',
        Description: 'asc',
        movefor: 'asc',
        iboxcode: 'asc',
        ovacode: 'asc',
      },
    };
  },
  watch: {
    'newMovement.Name'(newName) {
    if (this.isAddingNew || (this.selectedMovement && this.newMovement.Name !== this.selectedMovement.Name) ) {
      this.checkMovementName(newName);
    }
  },
  searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
  },
  created() {
    this.fetchMovements();
    this.checkPermissions();
  },
  computed: {
    filteredMovements() {
      return this.movements.filter(movement => {
        return Object.values(movement).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedMovements() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredMovements.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredMovements.length / this.rowsPerPage);
    },
    paginatedPages() {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage; // assuming you have a currentPage data property
    const maxPagesToShow = 5;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they are fewer than the maxPagesToShow
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Always show the first page
      pages.push(1);

      // Show a range around the current page
      let start = Math.max(2, currentPage - 4);
      let end = Math.min(totalPages - 1, currentPage + 4);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  },
  showPrevious() {
    return this.currentPage > 1;
  },
  showNext() {
    return this.currentPage < this.totalPages;
  }
  },
  methods: {
    goToPage(page) {
    if (page === '...' || page === this.currentPage) return;
    this.currentPage = page;
  },
    async checkMovementName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const movementExists = this.movements.some(movement => movement.Name.toLowerCase() === name.toLowerCase());
        if (movementExists) {
          this.nameError = 'Movement name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking movement name:', err);
      }
    },
    async fetchMovements() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/movement_list', config);
        this.movements = response.data[2];
        localStorage.setItem('movements', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching movement list:', error);
        this.error = 'Error fetching movement list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    checkPermissions() {
      const token = localStorage.getItem('access_token');
      this.hasPermissions = !!token; // Assuming that having a token means permissions are granted
    },
    openAddOverlay() {
      if (this.selectedMovement) {
        this.cancelEdit(); // Cancel editing if a movement is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewMovement(); // Reset new movement fields
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedMovement = null;
    },
    async saveMovement() {
      const toast = useToast();
      try {
        if (this.nameError) {
          console.error('Name error:', this.nameError);
          toast.error('Please fix the errors before saving.');
          return;
        }

        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No token found in localStorage');
          return;
        }

        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        if (!this.selectedMovement) {
          // Set additional fields for new movement
          this.newMovement.CreateDate = new Date().toISOString();
          this.newMovement.ModiDate = new Date().toISOString();
          this.newMovement.created_by = this.currentUserId; // Assuming `currentUserId` is set elsewhere
          this.newMovement.last_modified_by = this.currentUserId; // Assuming `currentUserId` is set elsewhere
        }
        this.newMovement.isDeletable = Boolean(this.newMovement.isDeletable);
        if (this.selectedMovement) {
          // Update existing movement
          await axios.put(`/update_movement/${this.selectedMovement.Movid}/`, this.newMovement, config);
          toast.success('Movement Updated Successfully!');
        } else {
          // Create new movement
          await axios.post('/create_movement', this.newMovement, config);
          toast.success('Movement Created Successfully!');
        }

        this.isAddingNew = false;
        this.selectedMovement = null;
        this.fetchMovements();
      } catch (error) {
        console.error('Error saving movement:', error);
        this.error = 'Error saving movement. Check console for details.';
      }
    },
    resetNewMovement() {
      this.newMovement = {
        Movid: 0,
        Name: '',
        Description: '',
        PreviousMove: 0,
        movefor: '',
        iboxcode: '',
        ovacode: '',
        companyid: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
    sortByColumn(column) {
      const currentDirection = this.sortDirection[column];
      const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
      this.sortDirection[column] = newDirection;
      this.movements.sort((a, b) => {
        if (a[column] < b[column]) return newDirection === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return newDirection === 'asc' ? 1 : -1;
        return 0;
      });
    },
    editMovement(movement) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedMovement = movement;
      this.newMovement = { ...movement };
    },
    async cancelEdit() {
      this.selectedMovement = null;
      this.resetNewMovement();
    },
    exportToCSV() {
      if (this.filteredMovements) {
        const csvData = this.filteredMovements.map(movement => [
          movement.Name,
          movement.Description,
          movement.movefor,
          movement.iboxcode,
          movement.ovacode,
          movement.isDeletable,
          movement.CreateDate,
          movement.ModiDate,
        ]);

        const csvContent = "data:text/csv;charset=utf-8," + csvData.map(row => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "movement_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style scoped>

</style>