<template>
  <div class="main">
    <div class="applicant-list">
      <h1 class="title">Applicant List</h1>
      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Add New Applicant</h2>
          <div style="min-width: 600px;">
            <div>
              <div v-if="nameError" class="error-message">{{ nameError }}</div>
              <input style="width: 95%;" v-model="newApplicant.applicantname" placeholder="Applicant Name" />
            </div>
            <div><input style="width: 95%;" v-model="newApplicant.address" placeholder="Address" /></div>
            <div class="row">
              <div class="column"><input v-model="newApplicant.city" placeholder="City" />
              </div>
              <div class="column">
                <v-select :clearable="false" style="margin-top: 5px; width: 90%;" v-model="newApplicant.state_id"
                  :options="states" :reduce="state => state.stateId" label="stateName" selectOnTab="True" ></v-select>
              </div>
              <div class="column"><input v-model="newApplicant.pin" placeholder="PIN CODE" />
              </div>
            </div>
            <div class="row">
              <div class="column">
                <v-select :clearable="false" style="margin-top: 5px; width: 90%;" v-model="newApplicant.countryid"
                  :options="countries" :reduce="country => country.CountryID" label="CountryName" selectOnTab="True"
                  placeholder="Country"></v-select>
              </div>
              <div class="column"><input v-model="newApplicant.pan_no" placeholder="PAN NO" /></div>
              <div class="column"><input v-model="newApplicant.gst_no" placeholder="GST NO" /></div>
            </div>
            <div class="row">
              <div class="column"><input v-model="newApplicant.phone" placeholder="Phone" /></div>
              <div class="column2"><input style="width: 100%;" v-model="newApplicant.website" placeholder="Website" />
              </div>
            </div>
            <div class="row">
              <div><input type="email" style="width: 95%;" v-model="newApplicant.email" placeholder="Email" /></div>
            </div>
            <div class="row">
              <div class="column">
                <div class="form-row">
                  <label for="BankName" class="form-label">Bank Name</label>
                  <select id="BankName" v-model="newApplicant.bank_id" class="custom-select">
                    <option value="0">Select Bank</option>
                    <option v-for="bank in banks" :key="bank.autoid" :value="bank.autoid">
                      {{ bank.BankName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="column">
                <div class="form-row">
                  <label for="Containerisinbill" class="form-label">Container is in bill</label>
                  <select id="Containerisinbill" v-model="newApplicant.isContInBill" class="custom-select">
                    <option v-for="isinbill in Containerisinbill" :key="isinbill.id" :value="isinbill.id">
                      {{ isinbill.Name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="hasPermissions">
              <button @click="saveApplicant" class="save-button">Save</button>
              <button @click="cancelAdd" class="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading">Loading Applicant list...</div>
      <div v-else-if="error">Error loading Applicant list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openOverlay" title="Add New Applicant" :disabled="selectedApplicant">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Applicant</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="applicant-table">
            <thead>
              <tr>
                <th>
                  <span>Applicant Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('applicantname')"></i>
                </th>
                <th>
                  <span>Address</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('address')"></i>
                </th>
                <th>
                  <span>City</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('city')"></i>
                </th>
                <th>
                  <span>State</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('state')"></i>
                </th>
                <th>
                  <span>PIN</span>
                </th>
                <th>
                  <span>Country</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('country')"></i>
                </th>
                <th>
                  <span>PAN</span>
                </th>
                <th>
                  <span>GST</span>
                </th>
                <th>
                  <span>Phone</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
                <th>
                  <span>Website</span>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(applicant) in paginatedApplicants" :key="applicant.applicantid"
                :class="{ 'editing': applicant === selectedApplicant }" @dblclick="editApplicant(applicant)">
                <td v-if="applicant === selectedApplicant">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <textarea v-model="newApplicant.applicantname" placeholder="Applicant Name"></textarea>
                </td>
                <td v-else>{{ applicant.applicantname }}</td>
                <td v-if="applicant === selectedApplicant">
                  <textarea v-model="newApplicant.address" placeholder="Address"></textarea>
                </td>
                <td v-else>{{ applicant.address }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.city" placeholder="City" />
                </td>
                <td v-else>{{ applicant.city }}</td>

                <td v-if="applicant === selectedApplicant">
                  <v-select :clearable="false" v-model="newApplicant.state_id" :options="states" selectOnTab="True"
                    :reduce="state => state.stateId" label="stateName" placeholder="State"></v-select>
                </td>
                <td v-else>{{ applicant.state }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.pin" placeholder="PIN CODE" />
                </td>
                <td v-else>{{ applicant.pin }}</td>
                <td v-if="applicant === selectedApplicant">
                  <v-select :clearable="false" v-model="newApplicant.countryid" :options="countries" selectOnTab="True"
                    :reduce="country => country.CountryID" label="CountryName" placeholder="Country"></v-select>
                </td>
                <td v-else>{{ applicant.country }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.pan_no" placeholder="PAN No" />
                </td>
                <td v-else>{{ applicant.pan_no }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.gst_no" placeholder="GST NO" />
                </td>
                <td v-else>{{ applicant.gst_no }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.phone" placeholder="Phone" />
                </td>
                <td v-else>{{ applicant.phone }}</td>
                <td v-if="applicant === selectedApplicant">
                  <textarea v-model="newApplicant.email" placeholder="Email"></textarea>
                </td>
                <td v-else>{{ applicant.email }}</td>
                <td v-if="applicant === selectedApplicant">
                  <input v-model="newApplicant.website" placeholder="Website" />
                </td>
                <td v-else>{{ applicant.website }}</td>
                <td v-if="hasPermissions">
                  <button v-if="applicant === selectedApplicant" @click="saveApplicant(applicant)"
                    class="save-button"><i class="fas fa-save"></i></button>
                  <button v-if="applicant === selectedApplicant" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editApplicant(applicant)" class="edit-button"><i
                      class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      applicants: [],
      states: [],
      countries: [],
      banks: [],
      nameError: '',
      isLoading: false,
      error: null,
      tooltipVisible: false,
      tooltipTimeout: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPageOptions: [10, 20, 30],
      rowsPerPage: 10,
      currentPage: 1,
      showExportOptions: false,
      isAddingNew: false,
      selectedApplicant: null,
      newApplicant: {
        applicantID: 0,
        applicantname: '',
        address: '',
        city: '',
        state_id: 0,
        state: '',
        pin: '',
        countryid: 0,
        country: '',
        pan_no: '',
        gst_no: '',
        phone: '',
        email: '',
        website: '',
        is_active: false,
        bank_id: '0',
        isContInBill: '0',
      },
      filters: {
        applicantID: '',
        applicantname: '',
        address: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        email: '',
        website: '',
        is_active: '',
        createDate: null,
        modiDate: null,
      },
      sortDirection: {
        applicantID: 'asc',
        applicantname: 'asc',
        address: 'asc',
        city: 'asc',
        state: 'asc',
        country: 'asc',
        phone: 'asc',
        email: 'asc',
        website: 'asc',
        is_active: 'asc',
        createDate: 'asc',
        modiDate: 'asc',
      },
      Containerisinbill: [],
      showOverlay: false,
    };
  },
  created() {
    this.Containerisinbill = [
      { id: 0, Name: 'No Container No' },
      { id: 1, Name: 'Container no In bill End' },
      { id: 2, Name: 'Single Container no In Report' },
      { id: 3, Name: 'Container no In Report End' },],
      this.fetchApplicants();
    this.checkPermissions();
    //this.fetchStates();
    //this.fetchCountries();
    document.addEventListener('keydown', (e) => {
      //alert(e.key)
      if (e.key == "Escape") {
        this.cancelEdit()
      }
    })
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      //alert('hi')
      this.fetchStates();
      this.fetchCountries();
      this.fetchBanks();
    })
  },
  watch: {
    'newApplicant.applicantname'(newName) {
      if (this.isAddingNew || (this.selectedApplicant && this.newApplicant.applicantname !== this.selectedApplicant.applicantname)) {
        //console.log('checking');
        this.checkApplicantName(newName);
      }
    },
    searchQuery() {
      // Reset to the first page when search query changes
      this.currentPage = 1;
    },
  },
  computed: {
    filteredApplicants() {
      return this.applicants
        .filter((applicant) => {
          return Object.keys(this.filters).every((key) => {
            return !this.filters[key] || String(applicant[key]).toLowerCase().includes(this.filters[key].toLowerCase());
          }) && Object.values(applicant).some((value) =>
            String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          for (const key in this.sortDirection) {
            if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
          }
          return 0;
        });
    },
    paginatedApplicants() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredApplicants.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredApplicants.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage; // assuming you have a currentPage data property
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        // Show all pages if they are fewer than the maxPagesToShow
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        // Always show the first page
        pages.push(1);

        // Show a range around the current page
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        // Always show the last page
        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    openOverlay() {
      this.showOverlay = true
      if (this.selectedApplicant) {
        this.cancelEdit(); // Cancel editing if an applicant is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewApplicant(); // Reset new applicant fields
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    async checkApplicantName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const applicantExists = this.applicants.some(applicant => applicant.applicantname.toLowerCase() === name.toLowerCase());
        if (applicantExists) {
          this.nameError = 'Applicant name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking applicant name:', err);
      }
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },

    async fetchBanks() {
      //this.isLoading = true;
      //this.error = null;
      this.banks = JSON.parse(localStorage.getItem('banks'));
      //console.log(this.banks);
      /*const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/banks', config);
        console.log(response)
        this.banks = response.data[2];
      } catch (error) {
        console.error('Error fetching countries:', error);
        this.error = 'Error fetching countries. Check console for details.';
      } finally {
        this.isLoading = false;
      }*/
    },
    async fetchCountries() {
      this.countries = JSON.parse(localStorage.getItem('countries'));
      //console.log(this.countries);
      /*this.isLoading = true;
      this.error = null;
      try {
        const response = await axios.get('/countries');
        this.countries = response.data[2];
      } catch (error) {
        console.error('Error fetching countries:', error);
        this.error = 'Error fetching countries. Check console for details.';
      } finally {
        this.isLoading = false;
      }*/
    },
    async fetchStates() {
      this.states = JSON.parse(localStorage.getItem('states'));
      //console.log(this.states);
      /*this.isLoading = true;
      this.error = null;
      try {
        const response = await axios.get(`/states`);
        this.states = response.data[2];
      } catch (error) {
        console.error('Error fetching states:', error);
        this.error = 'Error fetching states. Check console for details.';
      } finally {
        this.isLoading = false;
      }*/
    },
    async fetchApplicants() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/applicant_list/', config);
        this.applicants = response.data[2];
        localStorage.setItem('applicants', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching applicant list:', error);
        this.error = 'Error fetching applicant list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    checkPermissions() {
      const token = localStorage.getItem('access_token');
      this.hasPermissions = !!token; // Assuming that having a token means permissions are granted
    },
    openAddOverlay() {
      if (this.selectedApplicant) {
        this.cancelEdit(); // Cancel editing if an applicant is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewApplicant(); // Reset new applicant fields
    },
    getCurrentUserId() {
      var x = JSON.parse(localStorage.getItem('user'))
      //console.log(x.user_id);
      return x.user_id;
    },
    async saveApplicant() {
      const toast = useToast();
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      const token = localStorage.getItem('access_token');
      //const userId = this.getCurrentUserId();
      //const now = new Date().toISOString();

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const StateName = this.states.find(state => state.stateId === this.newApplicant.state_id);
        //console.log(StateName)
        if (StateName) {
          this.newApplicant.state = StateName.stateName
          //console.log(StateName)
        }
        const countryN = this.countries.find(country => country.CountryID === this.newApplicant.countryid);
        //console.log(countryN)
        if (countryN) {
          this.newApplicant.country = countryN.CountryName
          //console.log(countryN)
        }

        if (this.isAddingNew) {
          const payload = {
            ...this.newApplicant
          };
          //console.log(payload)
          await axios.post('/createapplicant/', payload, config);
          this.showOverlay = false
          toast.success('Applicant Created Successfully!');
        } else if (this.selectedApplicant) {
          const payload = {
            ...this.newApplicant
          };
          //console.log(payload)
          await axios.put(`/update_applicant/${this.selectedApplicant.applicantid}/`, payload, config);
          toast.success('Applicant Updated Successfully!');
        }

        this.fetchApplicants();
        this.isAddingNew = false;
        this.selectedApplicant = null;
        this.newApplicant = this.resetApplicant;
      } catch (error) {
        console.error('Error saving applicant:', error);
        toast.error('Error saving applicant. Check console for details.');
      }
    },
    resetNewApplicant() {
      this.newApplicant = {
        applicantID: 0,
        applicantname: '',
        address: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        email: '',
        website: '',
        is_active: false,
        bank_id: '0',
        isContInBill: '0',
      };
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedApplicant = null;
      this.showOverlay = false
    },
    editApplicant(applicant) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedApplicant = applicant;
      this.newApplicant = { ...applicant };
    },
    async cancelEdit() {
      this.selectedApplicant = null;
      this.resetNewApplicant();
    },
    exportToCSV() {
      if (this.filteredApplicants) {
        // Generate CSV data from the filteredApplicants
        const csvData = this.filteredApplicants.map(applicant => [
          applicant.applicantName,
          applicant.address,
          applicant.city,
          applicant.state,
          applicant.country,
          applicant.phone,
          applicant.email,
          applicant.website,
          applicant.is_active,
          applicant.createDate,
          applicant.modiDate,
        ]);

        // Create a CSV file and trigger the download
        const csvContent = "data:text/csv;charset=utf-8," + csvData.map(row => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "applicant_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
};
</script>

<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}


.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position above the button */
  left: 50%;
  margin-left: -60px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.applicant-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.add-button span {
  display: none;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150%;
}

.add-button:hover span {
  display: block;
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.error-message {
  color: #d9534f;
  /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px;
  /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  /* Light red background */
  display: inline-block;
}

.export-options {
  display: block;
  top: 20%;
  right: 1%;

}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.applicant-table {
  width: 100%;
  border-collapse: collapse;
}

.applicant-table th,
.applicant-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.applicant-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.applicant-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}


.save-button {
  background-color: green;
  color: white;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button {
  background-color: red;
  color: white;
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table span {
  margin-right: 10px;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.export-container {
  position: relative;
  /* Add this */
}


.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* Allow scrolling if content overflows */
  z-index: 1000;
  /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  /* Allow more width */
  max-height: 90%;
  /* Restrict height to avoid overflow */
  overflow-y: auto;
  /* Scroll if content overflows vertically */
  z-index: 1001;
  /* Ensure content is above overlay background */
}

.column {
  float: left;
  width: 33.33%;
}

.column2 {
  float: left;
  width: 60%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>