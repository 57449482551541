<template>
  <div class="main">
    <div class="body-content">
      <div v-if="isLoading">Loading Damage list...</div>
      <div v-else-if="error">Error loading Damage list: {{ error }}</div>
      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Damage List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Damage" :disabled="selectedDamage">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Damage</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="damage-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('description')"></i>
                </th>
                <th>
                  <span>Short Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('shortName')"></i>
                </th>
                <th>
                  <span>Status</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isActive')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="descriptionError" class="error-message">{{ descriptionError }}</div>
                  <input v-model="newDamage.description" placeholder="Description" />
                </td>
                <td><input v-model="newDamage.shortName" placeholder="Short Name" /></td>
                <td>
                  <select v-model="newDamage.isActive">
                    <option :value="0">Active</option>
                    <option :value="1">Inactive</option>
                  </select>
                </td>
                <td v-if="hasPermissions">
                  <button @click="saveDamage" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="damage in paginatedDamages" :key="damage.autoid"
                :class="{ 'editing': damage === selectedDamage }">
                <td class="row-edit" v-if="damage === selectedDamage">
                  <div v-if="descriptionError" class="error-message">{{ descriptionError }}</div>
                  <input v-model="newDamage.description" placeholder="Description" />
                </td>
                <td v-else>{{ damage.description }}</td>
                <td class="row-edit" v-if="damage === selectedDamage">
                  <input v-model="newDamage.shortName" placeholder="Short Name" />
                </td>
                <td v-else>{{ damage.shortName }}</td>
                <td class="row-edit" v-if="damage === selectedDamage">
                  <select v-model="newDamage.isActive">
                    <option :value="0">Active</option>
                    <option :value="1">Inactive</option>
                  </select>
                </td>
                <td v-else>{{ damage.isActive==0 ? 'Active' : 'Inactive' }}</td>
                <td v-if="hasPermissions">
                  <button v-if="damage === selectedDamage" @click="saveDamage(damage)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="damage === selectedDamage" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editDamage(damage)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      damages: [],
      descriptionError: '',
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedDamage: null,
      showExportOptions: false,
      newDamage: {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      },
      sortDirection: {
        description: 'asc',
        shortName: 'asc',
        isActive: 'asc',
      },
      showAddTooltip: false,
    };
  },
  created() {
    this.fetchDamages();
    this.checkPermissions();
  },
  computed: {
    resetDamage() {
      return {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    filteredDamages() {
      return this.damages.filter(damage => {
        return Object.values(damage).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedDamages() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredDamages.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDamages.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        pages.push(1);
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    }
  },
  watch: {
    'newDamage.description'(newDescription) {
      if (this.isAddingNew || (this.selectedDamage && this.newDamage.description !== this.selectedDamage.description)) {
        this.checkDamageDescription(newDescription);
      }
    },
    searchQuery() {
      this.currentPage = 1;
    },
  },
  methods: {
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    showTooltip() {
      this.showAddTooltip = true;
    },
    hideTooltip() {
      this.showAddTooltip = false;
    },
    async checkDamageDescription(description) {
      if (description.trim() === '') {
        this.descriptionError = '';
        return;
      }

      try {
        const damageExists = this.damages.some(damage => damage.description.toLowerCase() === description.toLowerCase() &&
          damage.autoid !== this.newDamage.autoid);
        if (damageExists) {
          this.descriptionError = 'Damage description already exists.';
        } else {
          this.descriptionError = '';
        }
      } catch (err) {
        console.error('Error checking damage description:', err);
      }
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async fetchDamages() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/damage_list', config);
        this.damages = response.data[2];
        localStorage.setItem('damages', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching damage list:', error);
        this.error = 'Error fetching damage list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async checkPermissions() {
      this.hasPermissions = true; // Set this according to your logic
    },
    openAddOverlay() {
      if (this.selectedDamage) {
        this.cancelEdit(); // Cancel editing if a damage is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewDamage(); // Reset new damage fields
    },
    async saveDamage() {
      const toast = useToast();
      if (this.descriptionError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      const token = localStorage.getItem('access_token');
      const userId = this.getCurrentUserId();
      const now = new Date().toISOString();

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const payload = {
          ...this.newDamage,
          created_by: userId,
          last_modified_by: userId,
          CreateDate: now,
          ModiDate: now,
        };

        if (this.isAddingNew) {
          await axios.post('/create_damage', payload, config);
          toast.success('Damage Created Successfully!');
        } else if (this.selectedDamage) {
          await axios.put(`/update_damage/${this.selectedDamage.autoid}`, payload, config);
          toast.success('Damage Updated Successfully!');
        }

        this.fetchDamages();
        this.isAddingNew = false;
        this.selectedDamage = null;
        this.newDamage = this.resetDamage;
      } catch (error) {
        console.error('Error saving damage:', error);
        toast.error('Error saving damage. Check console for details.');
      }
    },
    resetNewDamage() {
      this.newDamage = {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedDamage = null;
    },
    editDamage(damage) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedDamage = damage;
      this.newDamage = { ...damage };
    },
    async cancelEdit() {
      this.selectedDamage = null;
      this.resetNewDamage();
    },
    exportToCSV() {
      const csvContent = [
        ['Description', 'Short Name', 'Status'],
        ...this.damages.map(damage => [damage.description, damage.shortName, damage.isActive==0 ? 'Active' : 'Inactive']),
      ].map(e => e.join(",")).join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'damage_list.csv';
      link.click();
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
  },
};
</script>


<style scoped>

</style>