// store/index.js
import { createStore } from 'vuex';
import router from '../router';
import applicants from './modules/applicants';

export default createStore({
  state: {
    permissions: {}, // Store permissions here
    showSidebar: false, // Store sidebar state here
    isAuthenticated: !!localStorage.getItem('access_token'),
    user: JSON.parse(localStorage.getItem('user')) || { name: 'User' },
  },
  mutations: {
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    closeSidebar(state) {
      state.showSidebar = false;
    },
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async savePermissions({ commit }, permissions) {
      commit('setPermissions', permissions);
    },
    
    login({ commit }, { token, user }) {
      localStorage.setItem('access_token', token);
      localStorage.setItem('user', JSON.stringify(user));
      commit('setAuthentication', true);
      commit('setUser', user);
    },
    async logout({ commit }) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      commit('setAuthentication', false);
      commit('setUser', { name: 'User' });
      await router.push('/');
      window.location.reload();
      

    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    showSidebar: state => state.showSidebar,
  },
  modules: {
    applicants, // Register the applicants module
  },
});


